export const ROUTES = {
    CREATE_BUSINESS: "create-business",
    QR_RESOLVE: "qrresolve",
    BOOKING_DETAILS: "booking-details",
    ADD_VEHICLE: "add-vehicle",
    MODEL_LIST: "model-list",
    MIGRATION: "migration",
    STORE_DETAILS: "storeDetails",
    STORE_LIST: "storeList",
    STORE_SEARCH: "storeSearch",
    OPERATOR: "operator",
    ADD_STORE: "addstore",
    PLACES: "places",
    OUTLET: "outlet",
    IMAGE: "image",
    HEALTH_CHECK: "healthcheck",
    CHARGING_STATION: "charging-station",
    ONDEMAND_CHARGING: "ondemand",
    START_CHARGING: "start-charging",
    CHARGING_DETAILS: "charging-details",
    LOCATION_ONMAP: "location-onmap",
    PLAN_SELECTOR: "plan-selector",
    CREATE_ENERGY_PLAN: "create-energy-plan",
    CREATE_MONEY_PLAN: "create-money-plan",
    CREATE_COMPANY: "create-company",
    CREATE_PATNER: "create_patner",
    CREATE_OPERATOR: "create_operator",
    ADD_BANK: "add_back_account",
    PARTNER_MAN: 'partner',
    SETTLEMENT: 'settlement',
    SETTLEMENT_DETAILS: 'settlement_details',
    OPERATOR_STORES: 'operator_stores',
    OPERATOR_DETAILS: 'operator_details',
    CREATE_BUSINESS_OPERATOR: 'create_business_operator',
    WALLET_ACTIVATE: 'wallet_activate',
    WALLET_DEACTIVATE: 'wallet_deactivate',
    WALLET_INFO: 'get_wallet',
    CHARGER_ALIVE: 'charger_alive',
    STOP_CHARGE_ADMIN: 'stop-charge-admin',
    BOOKING_STATS: 'booking-stats',
    UPDATE_PRICING: 'update-pricing',
    // ADD_MONEY_TO_WALLET: 'add-money-to-wallet',
    TOKEN_OF_APOLOGY: 'token-of-apology',
  };
  