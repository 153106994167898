import { useState } from "react";
import { tokenOfApology } from "../../api";

const TokenOfApology = () => {
  const [formData, setFormData] = useState({
    amountINR: "",
    walletId: "",
    userId: "",
    orderId: "",
    orderType: "CONNECTOR_BOOKING",
    transactionType: "TOA",
  });
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  // Add these constants with current single options
  const orderTypeOptions = ["CONNECTOR_BOOKING"];
  const transactionTypeOptions = ["TOA"];

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      const result = await tokenOfApology(formData);
      setResponse(result);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto p-4 bg-white shadow-md rounded-lg">
      <h2 className="text-xl font-bold mb-4">Token of Apology</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        {Object.keys(formData).map((key) => {
          if (key === 'orderType') {
            return (
              <select
                key={key}
                name={key}
                value={formData[key]}
                onChange={handleChange}
                className="w-full p-2 border rounded-md"
                required
              >
                {orderTypeOptions.map(option => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
            );
          }
          if (key === 'transactionType') {
            return (
              <select
                key={key}
                name={key}
                value={formData[key]}
                onChange={handleChange}
                className="w-full p-2 border rounded-md"
                required
              >
                {transactionTypeOptions.map(option => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
            );
          }
          return (
            <input
              key={key}
              type="text"
              name={key}
              value={formData[key]}
              onChange={handleChange}
              placeholder={key}
              className="w-full p-2 border rounded-md"
              required
            />
          );
        })}
        <button
          type="submit"
          className="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600"
          disabled={loading}
        >
          {loading ? "Processing..." : "Token of Apology"}
        </button>
      </form>
      {response && (
        <div className="mt-4 p-4 bg-gray-50 rounded-lg">
          <h3 className="text-lg font-semibold text-green-600 mb-2">Success Response:</h3>
          <pre className="bg-gray-200 text-black-100 p-4 rounded-lg overflow-auto">
            <code>{JSON.stringify(response, null, 2)}</code>
          </pre>
        </div>
      )}
      {error && (
        <div className="mt-4 p-4 bg-red-50 rounded-lg">
          <h3 className="text-lg font-semibold text-red-600 mb-2">Error:</h3>
          <p className="text-red-500">{error}</p>
        </div>
      )}
    </div>
  );
};

export default TokenOfApology;
