import React, { useEffect, useState } from "react";
import { getOperatorBookingList } from "../../api";
import { checkLocationPermission } from "../../utils/location";

const BookingList = () => {
    const [formData, setFormData] = useState({
        operatorId: "",
        fetchWindow: "LAST_MONTH",
    });
    const [bookings, setBookings] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [jsonView, setJsonView] = useState(false);
    const [coordinates, setCoordinates] = useState({ latitude: null, longitude: null });
    
    useEffect(() => {
        checkLocationPermission(setCoordinates);
      }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitted(true);
        setLoading(true);
        setError("");

        const payload = {
            fetchWindow: formData?.fetchWindow,
            location: {
                latLng: {
                    latitude: coordinates?.latitude,
                    longitude: coordinates?.longitude
                },
                radiusInMeters: 50000
            }
        };

        try {
            const response = await getOperatorBookingList(formData?.operatorId, payload);
            setBookings(response || []);
        } catch (err) {
            setError(err?.message || "Failed to fetch bookings");
            setBookings([]);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="p-4 sm:p-6 max-w-4xl mx-auto bg-white rounded-xl shadow-md w-full sm:w-11/12 md:w-10/12 lg:w-4/5 xl:w-3/5">
            <h2 className="text-2xl font-semibold mb-4 text-center">Operator Booking List</h2>

            <form onSubmit={handleSubmit} className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                    <label className="block mb-1 font-medium">Operator ID</label>
                    <input
                        type="text"
                        name="operatorId"
                        value={formData.operatorId}
                        onChange={handleChange}
                        className="w-full p-2 border rounded-lg"
                        required
                    />
                </div>

                <div>
                    <label className="block mb-1 font-medium">Fetch Window</label>
                    <select
                        name="fetchWindow"
                        value={formData.fetchWindow}
                        onChange={handleChange}
                        className="w-full p-2 border rounded-lg"
                    >
                        <option value="LAST_DAY">Last Day</option>
                        <option value="LAST_WEEK">Last Week</option>
                        <option value="LAST_MONTH">Last Month</option>
                        <option value="LAST_YEAR">Last Year</option>
                        <option value="CUSTOM">Custom</option>
                    </select>
                </div>

                <button
                    type="submit"
                    className="w-full md:col-span-2 bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition"
                >
                    {loading ? "Fetching..." : "Get Bookings"}
                </button>
            </form>

            {error && <p className="text-red-500 mt-4 text-center">{error}</p>}

            {isSubmitted && (
                <div className="mt-6">
                    <h3 className="text-xl font-semibold mb-3">Booking Results:</h3>

                    <button
                        onClick={() => setJsonView(!jsonView)}
                        className="mb-4 w-full sm:w-auto px-4 py-2 bg-gray-800 text-white rounded-lg hover:bg-gray-700 transition"
                    >
                        {jsonView ? "View List" : "View JSON"}
                    </button>

                    {jsonView ? (
                        <pre className="bg-gray-100 p-4 rounded-md overflow-auto text-sm max-h-85 max-w-full text-wrap break-all">
                            {JSON.stringify(bookings, null, 2)}
                        </pre>
                    ) : loading ? (
                        <p>Loading bookings...</p>
                    ) : bookings?.bookings?.length ? (
                        <div className="flex flex-col gap-4">
                            {bookings?.bookings?.map((booking) => (
                                <div
                                    key={booking?.bookingId}
                                    className="border p-4 rounded-lg shadow hover:shadow-lg transition bg-gray-50 w-full max-w-3xl mx-auto"
                                >
                                    <h1 className="font-semibold text-lg mb-2">Booking Details</h1>

                                    <p className="text-gray-700"><strong>Booking ID:</strong> {booking?.bookingId || "N/A"}</p>
                                    <p className="text-gray-700"><strong>Status:</strong> {booking?.bookingStatus || "N/A"}</p>
                                    <p className="text-gray-700"><strong>User Status:</strong> {booking?.userFacingBookingStatus || "N/A"}</p>

                                    {/* Connector Meta Details */}
                                    <h2 className="font-semibold mt-2">Connector Details</h2>
                                    <p className="text-gray-700"><strong>Connector ID:</strong> {booking?.connectorMeta?.connectorId || "N/A"}</p>
                                    <p className="text-gray-700"><strong>Operator ID:</strong> {booking?.connectorMeta?.operatorId || "N/A"}</p>
                                    <p className="text-gray-700"><strong>Charging Station ID:</strong> {booking?.connectorMeta?.chargingStationId || "N/A"}</p>
                                    <p className="text-gray-700"><strong>EVSE ID:</strong> {booking?.connectorMeta?.evseId || "N/A"}</p>
                                    <p className="text-gray-700"><strong>Type:</strong> {booking?.connectorMeta?.type || "N/A"}</p>
                                    <p className="text-gray-700"><strong>Power Rating:</strong> {booking?.connectorMeta?.powerRatingKWH} kWh</p>

                                    {/* Payment Details */}
                                    <h2 className="font-semibold mt-2">Payment Details</h2>
                                    <p className="text-gray-700"><strong>Payment Required:</strong> {booking?.bookingForwardPaymentDetails?.paymentRequired ? "Yes" : "No"}</p>
                                    <p className="text-gray-700"><strong>Payment Status:</strong> {booking?.bookingForwardPaymentDetails?.paymentStatus || "N/A"}</p>
                                    <p className="text-gray-700"><strong>Total Fee:</strong> ₹{(booking?.bookingForwardPaymentDetails?.paymentCharges?.totalFee || 0) / 100}</p>
                                    <p className="text-gray-700"><strong>Fulfillment Fee:</strong> ₹{(booking?.bookingForwardPaymentDetails?.paymentCharges?.fulfillmentFee?.amount || 0) / 100}</p>

                                    {/* User Details */}
                                    <h2 className="font-semibold mt-2">User Details</h2>
                                    <p className="text-gray-700"><strong>Name:</strong> {booking?.userDetails?.fullName || "N/A"}</p>
                                    <p className="text-gray-700"><strong>Email:</strong> {booking?.userDetails?.emailId || "N/A"}</p>
                                    <p className="text-gray-700"><strong>Phone:</strong> {booking?.userDetails?.phoneNumber || "N/A"}</p>

                                    {/* Location Details */}
                                    {booking?.userLocation?.latLng && (
                                        <div>
                                            <h2 className="font-semibold mt-2">User Location</h2>
                                            <p className="text-gray-700"><strong>Latitude:</strong> {booking?.userLocation?.latLng?.latitude}</p>
                                            <p className="text-gray-700"><strong>Longitude:</strong> {booking?.userLocation?.latLng?.longitude}</p>
                                        </div>
                                    )}

                                    {/* Vehicle Details */}
                                    {booking?.vehicleDetails && (
                                        <div>
                                            <h2 className="font-semibold mt-2">Vehicle Details</h2>
                                            <p className="text-gray-700"><strong>Vehicle ID:</strong> {booking?.vehicleDetails?.vehicleId || "N/A"}</p>
                                            <p className="text-gray-700"><strong>RTO Number:</strong> {booking?.vehicleDetails?.rtoVehicleNumber || "N/A"}</p>
                                            <p className="text-gray-700"><strong>Verified:</strong> {booking?.vehicleDetails?.verified ? "Yes" : "No"}</p>

                                            {/* Model Details */}
                                            {booking?.vehicleDetails?.modelDetails && (
                                                <div>
                                                    <h2 className="font-semibold mt-2">Model Details</h2>
                                                    <p className="text-gray-700"><strong>Model Name:</strong> {booking?.vehicleDetails?.modelDetails?.name || "N/A"}</p>
                                                    <p className="text-gray-700"><strong>Vendor:</strong> {booking?.vehicleDetails?.modelDetails?.vendor || "N/A"}</p>
                                                    <p className="text-gray-700"><strong>Wheel Size:</strong> {booking?.vehicleDetails?.modelDetails?.wheelsize || "N/A"}</p>
                                                    <p className="text-gray-700"><strong>Battery Capacity:</strong> {booking?.vehicleDetails?.modelDetails?.batterySpecs?.capacityKWH || "N/A"} kWh</p>
                                                    <p className="text-gray-700"><strong>Fast Charge Allowed:</strong> {booking?.vehicleDetails?.modelDetails?.batterySpecs?.fastChargeAllowed ? "Yes" : "No"}</p>

                                                    {/* Charge Time Estimates */}
                                                    {booking?.vehicleDetails?.modelDetails?.batterySpecs?.chargeTimeEstimates?.length > 0 && (
                                                        <div>
                                                            <h2 className="font-semibold mt-2">Charge Time Estimates</h2>
                                                            {booking.vehicleDetails.modelDetails.batterySpecs.chargeTimeEstimates.map((estimate, index) => (
                                                                <p key={index} className="text-gray-700">
                                                                    <strong>{estimate.fromPercentage}% → {estimate.toPercentage}%:</strong> {estimate.timeInMinutes} mins at {estimate.withPowerKWH} kW
                                                                </p>
                                                            ))}
                                                        </div>
                                                    )}

                                                    {/* Vehicle Image */}
                                                    {booking?.vehicleDetails?.modelDetails?.imageUrl && (
                                                        <div className="mt-4">
                                                            <h2 className="font-semibold">Vehicle Image</h2>
                                                            <img
                                                                src={booking.vehicleDetails.modelDetails.imageUrl}
                                                                alt="Vehicle"
                                                                className="rounded-lg shadow-md w-full max-w-md"
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}


                                    {/* Actions */}
                                    {booking?.actions?.length > 0 && (
                                        <div>
                                            <h2 className="font-semibold mt-2">Actions</h2>
                                            <ul className="list-disc pl-5">
                                                {booking?.actions.map((action, index) => (
                                                    <li key={index} className="text-gray-700">{action}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p className="text-center text-gray-500">No bookings available</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default BookingList;
