import React, { useState, useEffect } from 'react';
import { getDefaultStoreOptions, createNewStore } from '../api';
import AddressInput from '../components/AddressToCoordinates';
import AddImage from '../components/AddImage';

const AddStore = () => {
  const [storeData, setStoreData] = useState(null);
  const [options, setOptions] = useState({});
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [averageStoreRating, setAverageStoreRating] = useState();
  const [responseData, setResponseData] = useState(null);

  // Fetch default store values
  useEffect(() => {
    const fetchDefaultValues = async () => {
      try {
        const defaultOptions = await getDefaultStoreOptions();

        if (!defaultOptions) {
          throw new Error("Default store options are missing.");
        }

        setStoreData({
          operatorId: defaultOptions.operatorId || '',
          thirdPartySourced: defaultOptions.thirdPartySourced,
          name: defaultOptions.name || '',
          description: defaultOptions.description || '',
          locationType: defaultOptions.locationType || defaultOptions.locationTypesOptions?.[0] || '',
          size: defaultOptions.size || defaultOptions.sizeOptions?.[0] || '',
          accessType: defaultOptions.accessType || defaultOptions.accessTypeOptions?.[0] || '',
          yearActiveSince: defaultOptions.yearActiveSince || 2020,
          locationTags: defaultOptions.locationTags || defaultOptions.locationTagsOptions?.[0] || '',
          amenities: {
            amenityTypes: defaultOptions.amenities?.amenityTypes || [],
          },
          location: {
            latitude: null,
            longitude: null,
            addressLine1: '',
            city: '',
            state: ''
          },
          googlePlaceDetails: { placeId: '' },
          mobileStore: true,
          timings: { available247: true },
          restrictionLevel: "PUBLIC",
          contactInfo: {
            primarySupportNumber: {
              countryCode: '91',
              phoneNumber: defaultOptions.contactInfo?.primarySupportNumber?.phoneNumber || '',
            },
          },
          chargerPricingRuleSet: {
            pricingRules: [
              {
                ruleType: "BASE_PRICE",
                chargingOperationsEntityType: "STORE",
                chargerPowerRatingToUnitPriceMap: {},
              },
            ],
          },
        });

        setOptions({
          powerRatingOptions: defaultOptions.chargerPowerRatingDenominationOptions?.flat() || [],
          ruleTypeOptions: ["BASE_PRICE"],
          chargingEntityOptions: ["STORE"],
          parkingLevelOptions: defaultOptions.parkingLevelOptions || [],
          accessTypeOptions: defaultOptions.accessTypeOptions || [],
          locationTypesOptions: defaultOptions.locationTypesOptions || [],
          locationTagsOptions: defaultOptions.locationTagsOptions || [],
          sizeOptions: defaultOptions.sizeOptions || [],
          cityOptions: defaultOptions.cityOptions || [],
          stateOptions: defaultOptions.stateOptions || [],
          amenityTypeOptions: defaultOptions.amenityTypeOptions || [],
        });

        setLoading(false);
      } catch (err) {
        console.error('Error fetching default store options:', err);
        setError('Failed to load default store details.');
        setLoading(false);
      }
    };

    fetchDefaultValues();
  }, []);

  // Function to update ruleType and chargingOperationsEntityType inside pricingRules
  const handlePricingRuleChange = (field, value) => {
    setStoreData((prevData) => ({
      ...prevData,
      chargerPricingRuleSet: {
        pricingRules: prevData?.chargerPricingRuleSet?.pricingRules?.map(
          (rule) => ({
            ...rule,
            [field]: value,
          })
        ),
      },
    }));
  };

  // Function to update chargerPowerRatingToUnitPriceMap based on selection
  const handlePowerRatingSelection = (e) => {
    const selectedRating = e.target.value;
    setStoreData((prevData) => ({
      ...prevData,
      chargerPricingRuleSet: {
        pricingRules: prevData?.chargerPricingRuleSet?.pricingRules?.map((rule) => ({
          ...rule,
          chargerPowerRatingToUnitPriceMap: {
            ...rule.chargerPowerRatingToUnitPriceMap,
            [selectedRating]: {
              rate: "",
              unit: "KWH",
            },
          },
        })),
      },
    }));
  };

  // Function to update rate and unit values for the selected power rating
  const handlePowerRatingChange = (ratingKey, field, value) => {
    setStoreData((prevData) => ({
      ...prevData,
      chargerPricingRuleSet: {
        pricingRules: prevData?.chargerPricingRuleSet?.pricingRules?.map((rule) => ({
          ...rule,
          chargerPowerRatingToUnitPriceMap: {
            ...rule.chargerPowerRatingToUnitPriceMap,
            [ratingKey]: {
              ...rule.chargerPowerRatingToUnitPriceMap[ratingKey],
              [field]: value,
            },
          },
        })),
      },
    }));
  };

  const handleLocationSelect = (locationDetails) => {
    setStoreData((prev) => ({
      ...prev,
      location: {
        latitude: locationDetails.lat,
        longitude: locationDetails.lng,
        addressLine1: locationDetails.address,
        // city: locationDetails.city,
        // state: locationDetails.state,
      },
      googlePlaceDetails: { placeId: locationDetails.placeId },
    }));
  };

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;

    setStoreData((prevData) => {
      // If city or state is selected, update the location object
      if (name === "city" || name === "state") {
        return {
          ...prevData,
          location: {
            ...prevData.location,
            [name]: value,
          },
        };
      }

      // Otherwise, update the main storeData object
      return {
        ...prevData,
        [name]: value,
      };
    });
  };


  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    const payload = {
      storeRequest: storeData,
      averageStoreRating: averageStoreRating || 0,
    };
    try {
      const response = await createNewStore(payload);
      setResponseData(response);
      alert("Store added successfully!");
    } catch (err) {
      console.error("Error creating store:", err);
      alert("Failed to create store.");
    }

    setSubmitting(false);
  };

  // Add this function before the return statement
  const handleUploadComplete = (imageUrl) => {
    setStoreData((prev) => ({
      ...prev,
      images: [...(prev.images || []), imageUrl]
    }));
  };

  if (loading) return <p>Loading default store details...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="max-w-3xl mx-auto mt-10 p-6 border rounded-lg shadow-lg">
      <h2 className="text-xl font-bold mb-4">Add Store</h2>
      <form onSubmit={handleSubmit}>

        {/* Dropdown for Rule Type inside pricingRules */}
        <div className="mb-4">
          <label className="block font-semibold">Rule Type:</label>
          <select
            name="ruleType"
            value={storeData?.chargerPricingRuleSet?.pricingRules[0]?.ruleType}
            onChange={(e) => handlePricingRuleChange("ruleType", e.target.value)}
            className="border p-2 rounded w-full"
          >
            {options?.ruleTypeOptions?.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>

        {/* Dropdown for Charging Operations Entity Type inside pricingRules */}
        <div className="mb-4">
          <label className="block font-semibold">
            Charging Operations Entity Type:
          </label>
          <select
            name="chargingOperationsEntityType"
            value={
              storeData?.chargerPricingRuleSet?.pricingRules[0]
                ?.chargingOperationsEntityType
            }
            onChange={(e) =>
              handlePricingRuleChange("chargingOperationsEntityType", e.target.value)
            }
            className="border p-2 rounded w-full"
          >
            {options?.chargingEntityOptions?.map((entity) => (
              <option key={entity} value={entity}>
                {entity}
              </option>
            ))}
          </select>
        </div>

        {/* Dropdown for Power Rating Selection */}
        <div className="mb-4">
          <label className="block font-semibold">Select Power Rating:</label>
          <select
            onChange={handlePowerRatingSelection}
            className="border p-2 rounded w-full"
          >
            <option value="">Select Power Rating</option>
            {options?.powerRatingOptions?.map((rating) => (
              <option key={rating} value={rating}>
                {rating}
              </option>
            ))}
          </select>
        </div>

        {/* Charger Power Rating Pricing Input Fields */}
        <div className="mb-4">
          {Object.entries(
            storeData?.chargerPricingRuleSet?.pricingRules[0]
              ?.chargerPowerRatingToUnitPriceMap
          ).map(([ratingKey, pricing]) => (
            <div key={ratingKey} className="mb-3 p-3 border rounded-lg">
              <p className="font-medium">{ratingKey}</p>
              <label className="block text-sm">Rate:</label>
              <input
                type="text"
                value={pricing?.rate}
                onChange={(e) =>
                  handlePowerRatingChange(ratingKey, "rate", e.target.value)
                }
                className="border p-2 rounded w-full"
              />

              <label className="block text-sm mt-2">Unit:</label>
              <input
                type="text"
                value={pricing?.unit}
                onChange={(e) =>
                  handlePowerRatingChange(ratingKey, "unit", e.target.value)
                }
                className="border p-2 rounded w-full"
              />
            </div>
          ))}
        </div>

        {/* Operator ID */}
        <label className="block mb-2">Operator ID:</label>
        <input
          type="text"
          name="operatorId"
          value={storeData?.operatorId}
          onChange={handleChange}
          className="w-full p-2 border rounded mb-4"
          placeholder="Enter Operator ID"
        />

        {/* Store Name */}
        <label className="block mb-2">Store Name:</label>
        <input
          type="text"
          name="name"
          value={storeData?.name}
          onChange={handleChange}
          className="w-full p-2 border rounded mb-4"
          placeholder="Enter Store Name"
        />

        {/* Description */}
        <label className="block mb-2">Description:</label>
        <textarea
          name="description"
          value={storeData?.description}
          onChange={handleChange}
          className="w-full p-2 border rounded mb-4"
          placeholder="Enter Store Description"
        ></textarea>

        {/* Location Type */}
        <label className="block mb-2">Location Type:</label>
        <select
          name="locationType"
          value={storeData?.locationType || ""}
          onChange={handleChange}
          className="w-full p-2 border rounded mb-4"
        >
          <option value="" disabled>Select Location Type</option>
          {options?.locationTypesOptions?.map((option) => (
            <option key={option} value={option}>
              {option.replace('_', ' ')}
            </option>
          ))}
        </select>

        {/* Location Tags */}
        <label className="block mb-2">Location Tags:</label>
        <select
          name="locationTags"
          value={storeData?.locationTags || ""}
          onChange={handleChange}
          className="w-full p-2 border rounded mb-4"
        >
          <option value="" disabled>Select Location Tag</option>
          {options?.locationTagsOptions?.map((option) => (
            <option key={option} value={option}>
              {option.replace('_', ' ')}
            </option>
          ))}
        </select>

        {/* Access Type */}
        <label className="block mb-2">Access Type:</label>
        <select
          name="accessType"
          value={storeData?.accessType || ""}
          onChange={handleChange}
          className="w-full p-2 border rounded mb-4"
        >
          <option value="" disabled>Select Access Type</option>
          {options?.accessTypeOptions?.map((option) => (
            <option key={option} value={option}>
              {option.replace('_', ' ')}
            </option>
          ))}
        </select>

        {/* Store Size */}
        <label className="block mb-2">Store Size:</label>
        <select
          name="size"
          value={storeData?.size || ""}
          onChange={handleChange}
          className="w-full p-2 border rounded mb-4"
        >
          <option value="" disabled>Select Store Size</option>
          {options?.sizeOptions?.map((option) => (
            <option key={option} value={option}>
              {option.replace('_', ' ')}
            </option>
          ))}
        </select>


        {/* Amenity Type Dropdown */}
        <label className="block mb-2">Amenities:</label>
        <select
          multiple
          name="amenities"
          value={storeData?.amenities?.amenityTypes || []} // Ensure it maps correctly
          onChange={(e) =>
            setStoreData((prev) => ({
              ...prev,
              amenities: {
                ...prev.amenities, // Preserve other existing fields if any
                amenityTypes: Array.from(e.target.selectedOptions, (option) => option.value),
              },
            }))
          }
          className="w-full p-2 border rounded mb-4"
        >
          {options?.amenityTypeOptions?.map((amenity) => (
            <option key={amenity} value={amenity}>
              {amenity.replace('_', ' ')}
            </option>
          ))}
        </select>

        {/* Address */}
        <label className="block mb-2">Address:</label>
        <AddressInput onLocationSelect={handleLocationSelect} />

        <label className="block mb-2 mt-4">State:</label>
        <select
          value={storeData?.location?.state}
          onChange={(e) =>
            setStoreData((prev) => ({
              ...prev,
              location: { ...prev.location, state: e.target.value },
            }))
          }
          className="w-full p-2 border rounded mb-4"
        >
          <option value="">Select State</option>
          {options?.stateOptions?.map((state) => (
            <option key={state} value={state}>
              {state}
            </option>
          ))}
        </select>

        <label className="block mb-2 ">City:</label>
        <select
          value={storeData?.location?.city}
          onChange={(e) =>
            setStoreData((prev) => ({
              ...prev,
              location: { ...prev.location, city: e.target.value },
            }))
          }
          className="w-full p-2 border rounded mb-4"
        >
          <option value="">Select City</option>
          {options?.cityOptions?.map((city) => (
            <option key={city} value={city}>
              {city}
            </option>
          ))}
        </select>


        {/* Primary Contact */}
        <label className="block mb-2">Primary Contact:</label>
        <input
          type="text"
          name="phoneNumber"
          value={storeData?.contactInfo?.primarySupportNumber?.phoneNumber}
          onChange={(e) =>
            setStoreData((prev) => ({
              ...prev,
              contactInfo: {
                ...prev.contactInfo,
                primarySupportNumber: {
                  ...prev.contactInfo.primarySupportNumber,
                  phoneNumber: e.target.value,
                },
              },
            }))
          }
          className="w-full p-2 border rounded mb-4"
          placeholder="Enter Contact Number"
        />

        {/* Average Store Rating */}
        <label className="block mb-2">Average Store Rating:</label>
        <input
          type="number"
          name="averageStoreRating"
          value={averageStoreRating || 0}
          onChange={(e) => setAverageStoreRating(Number(e.target.value))}
          className="w-full p-2 border rounded mb-4"
          placeholder="Enter Store Rating (0-10)"
        />

        <div className="bg-white p-6 rounded-lg shadow-md">
          <AddImage
            onUploadComplete={handleUploadComplete}
          />
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          disabled={submitting}
          className="bg-blue-500 text-white p-2 rounded w-full"
        >
          {submitting ? 'Adding...' : 'Add Store'}
        </button>
      </form>

      {responseData && (
        <div className="bg-gray-100 p-4 rounded mt-4">
          <h3 className="font-semibold mb-2">Response:</h3>
          <pre className="text-sm bg-white p-2 rounded border overflow-x-auto">
            {JSON.stringify(responseData, null, 2)}
          </pre>
        </div>
      )}
    </div>
  );
};

export default AddStore;
