import React, { useState } from "react";
import { PlusCircle, MinusCircle } from "lucide-react";
import { addVehicleModel } from "../api";

const AddVehicleComponent = () => {
  const [jsonResponse, setJsonResponse] = useState(null);
  const [newModel, setNewModel] = useState({
    imageUrl: "",
    vendor: "",
    sku: "",
    wheelsize: "",
    batterySpecs: {
      type: "",
      capacityKWH: "",
      chargeTimeEstimates: [
        {
          fromPercentage: 0,
          toPercentage: 80,
          withPowerKWH: "",
          timeInMinutes: "",
        },
      ],
      fastChargeAllowed: false,
      fastChargeRecommended: false,
    },
  });

  const handleInputChange = (e, index = null) => {
    const { name, value, type, checked } = e.target;
    setNewModel((prev) => {
      const newState = { ...prev };

      if (index !== null && name.startsWith("chargeTimeEstimates")) {
        const fieldName = name.split(".")[1];
        newState.batterySpecs.chargeTimeEstimates[index][fieldName] =
          type === "number" ? Number(value) : value;
      } else if (name.includes(".")) {
        const [parent, child] = name.split(".");
        newState[parent][child] =
          type === "checkbox"
            ? checked
            : type === "number"
            ? Number(value)
            : value;
      } else {
        newState[name] = value;
      }

      return newState;
    });
  };

  const handleAddChargeTime = () => {
    setNewModel((prev) => ({
      ...prev,
      batterySpecs: {
        ...prev.batterySpecs,
        chargeTimeEstimates: [
          ...prev.batterySpecs.chargeTimeEstimates,
          {
            fromPercentage: 0,
            toPercentage: 80,
            withPowerKWH: "",
            timeInMinutes: "",
          },
        ],
      },
    }));
  };

  const handleRemoveChargeTime = (index) => {
    setNewModel((prev) => ({
      ...prev,
      batterySpecs: {
        ...prev.batterySpecs,
        chargeTimeEstimates: prev.batterySpecs.chargeTimeEstimates.filter(
          (_, i) => i !== index
        ),
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      imageUrl: newModel.imageUrl,
      vendor: newModel.vendor.toUpperCase(),
      sku: newModel.sku,
      wheelsize: newModel.wheelsize,
      batterySpecs: {
        type: newModel.batterySpecs.type.toUpperCase(),
        capacityKWH: Number(newModel.batterySpecs.capacityKWH),
        chargeTimeEstimates: newModel.batterySpecs.chargeTimeEstimates.map(
          (estimate) => ({
            fromPercentage: Number(estimate.fromPercentage),
            toPercentage: Number(estimate.toPercentage),
            withPowerKWH: Number(estimate.withPowerKWH),
            timeInMinutes: Number(estimate.timeInMinutes),
          })
        ),
        fastChargeAllowed: newModel?.batterySpecs?.fastChargeAllowed,
        fastChargeRecommended: newModel?.batterySpecs?.fastChargeRecommended,
      },
    };

    try {
      const response = await addVehicleModel(payload);
      const responseData = await response.json();
      setJsonResponse(responseData);
      if (response.ok) {
        setNewModel({
          imageUrl: "",
          vendor: "",
          sku: "",
          wheelsize: "",
          batterySpecs: {
            type: "",
            capacityKWH: "",
            chargeTimeEstimates: [
              {
                fromPercentage: 0,
                toPercentage: 80,
                withPowerKWH: "",
                timeInMinutes: "",
              },
            ],
            fastChargeAllowed: false,
            fastChargeRecommended: false,
          },
        });
      } else {
        console.error("Failed to add model");
      }
    } catch (error) {
      console.error("Error adding model:", error);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Add New Vehicle Model</h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block mb-1">Vendor</label>
          <input
            type="text"
            name="vendor"
            value={newModel.vendor}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
            required
          />
        </div>

        <div>
          <label className="block mb-1">SKU</label>
          <input
            type="text"
            name="sku"
            value={newModel.sku}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
            required
          />
        </div>

        <div>
          <label className="block mb-1">Image Url</label>
          <input
            type="text"
            name="imageUrl"
            value={newModel.imageUrl}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
            required
          />
        </div>

        <div>
          <label className="block mb-1">Wheel Size</label>
          <select
            name="wheelsize"
            value={newModel.wheelsize}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
            required
          >
            <option value="">Select Wheel Size</option>
            <option value="TWO_WHEELER">Two Wheeler</option>
            <option value="THREE_WHEELER">Three Wheeler</option>
            <option value="FOUR_WHEELER">Four Wheeler</option>
            <option value="HEAVY_VEHICLE">Heavy Vehicle</option>
          </select>
        </div>

        <div>
          <label className="block mb-1">Battery Type</label>
          <input
            type="text"
            name="batterySpecs.type"
            value={newModel.batterySpecs.type}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
            required
          />
        </div>

        <div>
          <label className="block mb-1">Battery Capacity (kWh)</label>
          <input
            type="number"
            name="batterySpecs.capacityKWH"
            value={newModel.batterySpecs.capacityKWH}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
            required
          />
        </div>

        <div>
          <label className="block mb-1">Charge Time Estimates</label>
          {newModel.batterySpecs.chargeTimeEstimates.map((estimate, index) => (
            <div key={index} className="mb-4 space-y-2">
              <div>
                <label>From Percentage</label>
                <input
                  type="number"
                  name={`chargeTimeEstimates.fromPercentage`}
                  value={estimate.fromPercentage}
                  onChange={(e) => handleInputChange(e, index)}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div>
                <label>To Percentage</label>
                <input
                  type="number"
                  name={`chargeTimeEstimates.toPercentage`}
                  value={estimate.toPercentage}
                  onChange={(e) => handleInputChange(e, index)}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div>
                <label>Charge Power (kW)</label>
                <input
                  type="number"
                  name={`chargeTimeEstimates.withPowerKWH`}
                  value={estimate.withPowerKWH}
                  onChange={(e) => handleInputChange(e, index)}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div>
                <label>Charge Time (minutes)</label>
                <input
                  type="number"
                  name={`chargeTimeEstimates.timeInMinutes`}
                  value={estimate.timeInMinutes}
                  onChange={(e) => handleInputChange(e, index)}
                  className="w-full p-2 border rounded"
                />
              </div>
              <button
                type="button"
                onClick={() => handleRemoveChargeTime(index)}
                className="text-red-500"
              >
                <MinusCircle className="inline-block mr-2" /> Remove Estimate
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={handleAddChargeTime}
            className="text-blue-500"
          >
            <PlusCircle className="inline-block mr-2" /> Add Estimate
          </button>
        </div>

        <div>
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              name="batterySpecs.fastChargeAllowed"
              checked={newModel.batterySpecs.fastChargeAllowed}
              onChange={handleInputChange}
            />
            <span>Fast Charge Allowed</span>
          </label>
        </div>

        <div>
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              name="batterySpecs.fastChargeRecommended"
              checked={newModel.batterySpecs.fastChargeRecommended}
              onChange={handleInputChange}
            />
            <span>Fast Charge Recommended</span>
          </label>
        </div>

        <button type="submit" className="bg-blue-500 text-white p-2 rounded">
          Submit
        </button>
      </form>
      {jsonResponse && (
        <div className="mt-4 p-4 border rounded bg-gray-100">
          <h2 className="text-lg font-bold">JSON Response:</h2>
          <pre className="whitespace-pre-wrap break-words text-sm">
            {JSON.stringify(jsonResponse, null, 2)}
          </pre>
        </div>
      )}
    </div>
  );
};

export default AddVehicleComponent;
