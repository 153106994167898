import React, { useState } from "react";
import { createCompany } from "../../api";

const CreateCompany = () => {
  const [formData, setFormData] = useState({
    legalEntity: "",
    entityType: "",
    panNumber: "",
    registeredBusinessName: "",
    displayName: "",
    primaryPhoneNumber: {
      countryCode: "",
      phoneNumber: "",
    },
    primaryEmailId: "",
  });

  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [responseData, setResponseData] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith("primaryPhoneNumber")) {
      setFormData({
        ...formData,
        primaryPhoneNumber: {
          ...formData.primaryPhoneNumber,
          [name.split(".")[1]]: value,
        },
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await createCompany(formData);
      setSuccess(true);
      setError("");
      setResponseData(response);
    } catch (err) {
      setSuccess(false);
      setError(err.message || "Something went wrong!");
      setResponseData(null);
    }
  };

  return (
    <div className="max-w-lg mx-auto p-4">
      <h1 className="text-2xl font-semibold text-center mb-4">Create Company</h1>

      {success && <div className="text-green-500 mb-4">Company created successfully!</div>}
      {error && <div className="text-red-500 mb-4">{error}</div>}

      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Legal Entity */}
        <div>
          <label htmlFor="legalEntity" className="block text-sm font-medium text-gray-700">Legal Entity</label>
          <input
            type="text"
            id="legalEntity"
            name="legalEntity"
            value={formData.legalEntity}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
            placeholder="Enter Legal Entity"
            required
          />
        </div>

        {/* Entity Type */}
        <div>
          <label htmlFor="entityType" className="block text-sm font-medium text-gray-700">Entity Type</label>
          <input
            type="text"
            id="entityType"
            name="entityType"
            value={formData.entityType}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
            placeholder="Enter Entity Type"
            required
          />
        </div>

        {/* PAN Number */}
        <div>
          <label htmlFor="panNumber" className="block text-sm font-medium text-gray-700">PAN Number</label>
          <input
            type="text"
            id="panNumber"
            name="panNumber"
            value={formData.panNumber}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
            placeholder="Enter PAN Number"
            required
          />
        </div>

        {/* Registered Business Name */}
        <div>
          <label htmlFor="registeredBusinessName" className="block text-sm font-medium text-gray-700">Registered Business Name</label>
          <input
            type="text"
            id="registeredBusinessName"
            name="registeredBusinessName"
            value={formData.registeredBusinessName}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
            placeholder="Enter Registered Business Name"
            required
          />
        </div>

        {/* Display Name */}
        <div>
          <label htmlFor="displayName" className="block text-sm font-medium text-gray-700">Display Name</label>
          <input
            type="text"
            id="displayName"
            name="displayName"
            value={formData.displayName}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
            placeholder="Enter Display Name"
            required
          />
        </div>

        {/* Country Code */}
        <div>
          <label htmlFor="countryCode" className="block text-sm font-medium text-gray-700">Country Code</label>
          <input
            type="text"
            id="countryCode"
            name="primaryPhoneNumber.countryCode"
            value={formData.primaryPhoneNumber.countryCode}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
            placeholder="Enter Country Code"
            required
          />
        </div>

        {/* Phone Number */}
        <div>
          <label htmlFor="primaryPhoneNumber" className="block text-sm font-medium text-gray-700">Phone Number</label>
          <input
            type="text"
            id="primaryPhoneNumber"
            name="primaryPhoneNumber.phoneNumber"
            value={formData.primaryPhoneNumber.phoneNumber}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
            placeholder="Enter Phone Number"
            required
          />
        </div>

        {/* Email */}
        <div>
          <label htmlFor="primaryEmailId" className="block text-sm font-medium text-gray-700">Email</label>
          <input
            type="email"
            id="primaryEmailId"
            name="primaryEmailId"
            value={formData.primaryEmailId}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
            placeholder="Enter Email"
            required
          />
        </div>

        <button
          type="submit"
          className="w-full bg-blue-500 text-white p-2 rounded-md mt-4"
        >
          Create Company
        </button>
      </form>

      {/* JSON Response View */}
      {responseData && (
        <div className="mt-6 p-4 border border-gray-300 rounded-md bg-gray-100">
          <h2 className="text-lg font-semibold mb-2">Response Data:</h2>
          <pre className="text-sm text-gray-700 overflow-x-auto">
            {JSON.stringify(responseData, null, 2)}
          </pre>
        </div>
      )}
    </div>
  );
};

export default CreateCompany;
