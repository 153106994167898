import { useState } from "react";
import { confirmSettlement, initiateSettlement, unstageSettlement } from "../../api";

const Settlement = () => {
  const [partyType, setPartyType] = useState("");
  const [partyId, setPartyId] = useState("");
  const [settlementId, setSettlementId] = useState("");
  const [amountINR, setAmountINR] = useState(null);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  // Separate loading states
  const [initLoading, setInitLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [unstageLoading, setUnstageLoading] = useState(false);

  // Initiate Settlement
  const handleInitiateSettlement = async () => {
    if (!partyType || !partyId) {
      setError("Please enter Party Type and Party ID");
      return;
    }
    setError(null);
    setInitLoading(true);

    try {
      const data = await initiateSettlement(partyType, partyId);
      setResponse(data);
      setSettlementId(data?.settlementId);
      setAmountINR(data?.amountINR);
    } catch (err) {
      setError("Failed to initiate settlement");
    } finally {
      setInitLoading(false);
    }
  };

  // Confirm Settlement
  const handleConfirmSettlement = async () => {
    setError(null);
    setConfirmLoading(true);

    const payload = {
      settlementId,
      partyId,
      partyType,
      solutionProviderType: "PG",
      solutionProviderName: "razorpay",
      solutionProviderTransactionId: "txn1",
      settlementMode: "IMPS",
      utrNumber: "390999999999",
      amountINR,
    };

    try {
      const data = await confirmSettlement(payload);
      setResponse(data);
    } catch (err) {
      setError("Failed to confirm settlement");
    } finally {
      setConfirmLoading(false);
    }
  };

  // Unstage Settlement
  const handleUnstageSettlement = async () => {
    if (!settlementId) {
      setError("Settlement ID is missing.");
      return;
    }
    setError(null);
    setUnstageLoading(true);

    try {
      const data = await unstageSettlement(partyType, partyId, settlementId);
      setResponse(data);
    } catch (err) {
      setError("Failed to unstage settlement");
    } finally {
      setUnstageLoading(false);
    }
  };

  return (
    <div className="max-w-xl mx-auto mt-10 p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-bold mb-4">Settlement API Actions</h2>

      {/* Input Fields */}
      <input
        type="text"
        placeholder="Enter Party Type"
        value={partyType}
        onChange={(e) => setPartyType(e.target.value)}
        className="w-full p-2 border rounded-lg mb-2"
      />
      <input
        type="text"
        placeholder="Enter Party ID"
        value={partyId}
        onChange={(e) => setPartyId(e.target.value)}
        className="w-full p-2 border rounded-lg mb-4"
      />

      {/* Initiate Settlement Button */}
      <button
        onClick={handleInitiateSettlement}
        disabled={initLoading}
        className={`w-full p-2 rounded-lg mb-2 ${
          initLoading ? "bg-gray-400 cursor-not-allowed" : "bg-blue-600 hover:bg-blue-700 text-white"
        }`}
      >
        {initLoading ? "Processing..." : "Init Settlement"}
      </button>

      {/* Confirm Settlement Button */}
      <button
        onClick={handleConfirmSettlement}
        disabled={confirmLoading}
        className={`w-full p-2 rounded-lg mb-2 ${
          confirmLoading ? "bg-gray-400 cursor-not-allowed" : "bg-green-600 hover:bg-green-700 text-white"
        }`}
      >
        {confirmLoading ? "Processing..." : "Confirm Settlement"}
      </button>

      {/* Unstage Settlement Button */}
      <button
        onClick={handleUnstageSettlement}
        disabled={unstageLoading}
        className={`w-full p-2 rounded-lg ${
          unstageLoading ? "bg-gray-400 cursor-not-allowed" : "bg-red-600 hover:bg-red-700 text-white"
        }`}
      >
        {unstageLoading ? "Processing..." : "Unstage Settlement"}
      </button>

      {/* Error Message */}
      {error && <p className="text-red-500 mt-3">{error}</p>}

      {/* API Response */}
      {response && (
        <div className="mt-4 p-3 border rounded-md bg-gray-100">
          <h3 className="font-semibold">API Response:</h3>
          <pre className="text-sm text-gray-700">{JSON.stringify(response, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default Settlement;
