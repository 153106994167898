import React, { useState } from "react";
import { deactivateWallet } from "../../api";

const WalletDeactivation = () => {
    const [searchType, setSearchType] = useState("userId");
      const [searchValue, setSearchValue] = useState("");
      const [loading, setLoading] = useState(false);
      const [response, setResponse] = useState(null);
      const [error, setError] = useState(null);
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setResponse(null);
    
        try {
          const result = await deactivateWallet({ searchType, searchValue });
          setResponse(result);
        } catch (err) {
          setError(err)
          if (err.response && err.response.data && err.response.data.message) {
            setError(err.response.data.message);
          } else {
            setError("Something went wrong!");
          }
        } finally {
          setLoading(false);
        }
      };
    

    return (
        <div className="max-w-md mx-auto p-4 bg-white shadow-lg rounded-lg">
      <h2 className="text-xl font-semibold mb-4">Deactivate Wallet</h2>

      <form onSubmit={handleSubmit} className="space-y-4">
        <label className="block text-gray-700">Search by:</label>
        <select
          value={searchType}
          onChange={(e) => setSearchType(e.target.value)}
          className="w-full p-2 border rounded"
        >
          <option value="userId">User ID</option>
          <option value="phoneNumber">Phone Number</option>
          <option value="walletId">Wallet ID</option>
        </select>

        {/* Input field */}
        <label className="block text-gray-700">Enter {searchType}:</label>
        <input
          type="text"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          className="w-full p-2 border rounded"
          required
        />

        {/* Submit button */}
        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
          disabled={loading}
        >
          {loading ? "Deactivating..." : "Deactivate Wallet"}
        </button>
      </form>

      {/* Response Messages */}
      {response && <p className="mt-4 text-green-600">Wallet deactivated successfully!</p>}
      {error && <p className="mt-4 text-red-600">{error}</p>}
    </div>
  );
}

export default WalletDeactivation;