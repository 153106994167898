import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const PlanSelector = () => {
  const [selectedPlan, setSelectedPlan] = useState(""); 
  const navigate = useNavigate(); 

  const handleNavigation = () => {
    if (selectedPlan === "energy") {
      navigate("/create-energy-plan"); 
    } else if (selectedPlan === "money") {
      navigate("/create-money-plan");
    }
  };

  return (
    <div className="item-center">
      <div className="bg-white shadow-lg rounded-lg p-8 w-full">
        <h1 className="text-2xl font-bold mb-6 text-gray-800 text-center">
          Plan Selector
        </h1>
        <div className="flex flex-col items-center gap-6">
          <div className="w-full max-w-md">
            <label
              htmlFor="plan-select"
              className="block text-gray-700 font-medium mb-2 text-center"
            >
              Select Plan Type:
            </label>
            <select
              id="plan-select"
              value={selectedPlan}
              onChange={(e) => setSelectedPlan(e.target.value)}
              className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:ring focus:ring-blue-200"
            >
              <option value="" disabled>
                Select
              </option>
              <option value="energy">Energy-Based Plan</option>
              <option value="money">Money-Based Plan</option>
            </select>
          </div>

          <button
            onClick={handleNavigation}
            className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-300"
            disabled={!selectedPlan}
          >
            {selectedPlan
              ? `Go to ${selectedPlan === "energy" ? "Energy" : "Money-Based"} Plan`
              : "Select a Plan"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlanSelector;
