import React, { useState } from "react";
import { getWalletDetails } from "../../api"; // Import API function

const GetWallet = () => {
  const [searchType, setSearchType] = useState("userId");
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setResponse(null);

    try {
      const result = await getWalletDetails(searchType, searchValue);
      setResponse(result);
    } catch (err) {
      if (err.response?.data?.message) {
        setError(err.response.data.message);
      } else {
        setError("Something went wrong!");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto p-4 bg-white shadow-lg rounded-lg">
      <h2 className="text-xl font-semibold mb-4">Get Wallet Details</h2>

      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Dropdown for search*/}
        <label className="block text-gray-700">Search by:</label>
        <select
          value={searchType}
          onChange={(e) => setSearchType(e.target.value)}
          className="w-full p-2 border rounded"
        >
          <option value="userId">User ID</option>
          <option value="phoneNumber">Phone Number</option>
          <option value="walletId">Wallet ID</option>
        </select>

        {/* Input field for search value */}
        <label className="block text-gray-700">Enter {searchType}:</label>
        <input
          type="text"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          className="w-full p-2 border rounded"
          required
        />

        {/* Submit button */}
        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
          disabled={loading}
        >
          {loading ? "Fetching..." : "Get Wallet"}
        </button>
      </form>

      {response && (
        <div className="mt-4 p-3 bg-gray-100 text-green-700 rounded">
          <strong>Wallet Details:</strong>
          <pre className="bg-gray-100 p-2 rounded text-sm text-black overflow-auto">
            {JSON.stringify(response, null, 2)}
          </pre>
        </div>
      )}
      {error && <p className="mt-4 text-red-600">{error}</p>}
    </div>
  );
};

export default GetWallet;
