export const AuthService = {
  getToken() {
    try {
      const authStr = localStorage.getItem("auth");
      if (!authStr) return "";
      const auth = JSON.parse(authStr);
      return auth.token ?? "";
    } catch (err) {
      return "";
    }
  },

  setToken(data) {
    try {
      localStorage.setItem("auth", JSON.stringify(data));
      console.log("Auth stored:", data);
    } catch (err) {
      throw(err)
    }
  },

  clearToken() {
    try {
      localStorage.removeItem("auth");
    } catch (err) {
      throw(err)
    }
  },
};
