import { ROUTES } from "../routes";

export const SIDEBAR_TILES = [
  {
    title: "Model",
    submenu: true,
    submenuItems: [
      { title: "Add Model", path: `/${ROUTES.ADD_VEHICLE}` },
      { title: "Model List", path: `/${ROUTES.MODEL_LIST}` },
    ],
  },
  {
    title: "Store",
    submenu: true,
    submenuItems: [
      { title: "Store Details", path: `/${ROUTES.STORE_DETAILS}` },
      { title: "Store Search", path: `/${ROUTES.STORE_SEARCH}` },
      { title: "Charging Stations", path: `/${ROUTES.CHARGING_STATION}` },
      { title: "Add Store", path: `/${ROUTES.ADD_STORE}` },
      { title: "User Location", path: `/${ROUTES.LOCATION_ONMAP}` },
      { title: "Update Pricing", path: `/${ROUTES.UPDATE_PRICING}` },
    ],
  },
  { title: "Plan Selector", path: `/${ROUTES.PLAN_SELECTOR}` },
  {
    title: "Company",
    submenu: true,
    submenuItems: [
      { title: "Create Company", path: `/${ROUTES.CREATE_COMPANY}` },
      { title: "Add Bank Account", path: `/${ROUTES.ADD_BANK}` },
      { title: "Create Operator", path: `/${ROUTES.CREATE_OPERATOR}`},
      { title: "Create Patner", path: `/${ROUTES.CREATE_PATNER}` },
      { title: "Create Business User", path: `/${ROUTES.CREATE_BUSINESS}` },
      { title: "Activate", path: `/${ROUTES.PARTNER_MAN}` },

    ],
  },
  {
    title: "Settlement",
    submenu: true,
    submenuItems: [
      { title: "Settlement", path: `/${ROUTES.SETTLEMENT}` },
      { title: "Settlements Details", path: `/${ROUTES.SETTLEMENT_DETAILS}` },
    ],
  },
  {
    title: "Operator",
    submenu: true,
    submenuItems: [
      { title: "Stores of Operator", path: `/${ROUTES.OPERATOR_STORES}` },
      { title: "Booking of Operator", path: `/${ROUTES.OPERATOR_DETAILS}` },
      { title: "Create User Operator", path: `/${ROUTES.CREATE_BUSINESS_OPERATOR}` },
      { title: "Booking Details", path: `/${ROUTES.BOOKING_DETAILS}` },
    ],
  },
  {
    title: "Wallet",
    submenu: true,
    submenuItems: [
      { title: "Wallet Activate", path: `/${ROUTES.WALLET_ACTIVATE}` },
      { title: "Wallet Deactivate", path: `/${ROUTES.WALLET_DEACTIVATE}` },
      { title: "Get Wallet", path: `/${ROUTES.WALLET_INFO}` },
      // { title: "Add Money to Wallet", path: `/${ROUTES.ADD_MONEY_TO_WALLET}` },
      { title: "Token of Apology", path: `/${ROUTES.TOKEN_OF_APOLOGY}` },
    ],
  },
  {
    title: "Charger Status",
    submenu: true,
    submenuItems: [
      { title: "Charger Alive", path: `/${ROUTES.CHARGER_ALIVE}` },
      { title: "Stop Charger", path: `/${ROUTES.STOP_CHARGE_ADMIN}` },
    ],
  },
  {
    title: "Analytics",
    submenu: true,
    submenuItems: [
      { title: "Booking Stats", path: `/${ROUTES.BOOKING_STATS}` },
    ],
  },
  { title: "Home", spacing: true, path: `/${ROUTES.HOME}` },
  { title: "QR Resolve", path: `/${ROUTES.QR_RESOLVE}`  },
  { title: "Operator List", path: `/${ROUTES.OPERATOR}`},
  { title: "Migration Details", path: `/${ROUTES.MIGRATION}` },
  { title: "Location", path: `/${ROUTES.PLACES}`},
  { title: "HealthCheck", path: `/${ROUTES.HEALTH_CHECK}`, visibleTo: "INTERNAL_ADMIN" },
  { title: "On Demand", path: `/${ROUTES.ONDEMAND_CHARGING}` },
  { title: "Logout", spacing: true }
];

export const SIDEBAR_BREAKPOINT = 1024;
