import { useState } from "react";
import { addBankAccount, activateBankAccount, activateCompany } from "../../api"; 

const AddBank = () => {
  const [formData, setFormData] = useState({
    companyId: "",
    accountHolderName: "",
    ifscCode: "",
    accountNumber: "",
    accountNumberDoubleConfirmation: "",
  });

  const [bankDetails, setBankDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [responseData, setResponseData] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleAddBankAccount = async () => {
    setLoading(true);
    setMessage("");

    try {
      const response = await addBankAccount(formData);
      setBankDetails(response); 
      setResponseData(response); 
      setMessage("Bank account added successfully!");
    } catch (error) {
      console.error("Error adding bank account:", error);
      setMessage("Failed to add bank account.");
    } finally {
      setLoading(false);
    }
  };

  const handleActivateBankAccount = async () => {
    if (!bankDetails) {
      console.error("Bank details not found. Please add a bank account first.");
      return;
    }

    const entityId = bankDetails?.entityId || bankDetails?.companyId;
    const bankAccountId = bankDetails?.bankAccountId || bankDetails?.id;

    if (!entityId || !bankAccountId) {
      console.error("Missing entityId or bankAccountId. Check API response.");
      return;
    }

    try {
      const response = await activateBankAccount(entityId, bankAccountId);
      setResponseData(response);
      setMessage("Bank account activated successfully!");
    } catch (error) {
      console.error("Error activating bank account:", error);
      setMessage("Failed to activate bank account.");
    }
  };

  const handleActivateCompany = async () => {
    if (!formData.companyId) {
      console.error("Company ID is required.");
      return;
    }

    try {
      const response = await activateCompany(formData.companyId);
      setResponseData(response);
      setMessage("Company activated successfully!");
    } catch (error) {
      console.error("Error activating company:", error);
      setMessage("Failed to activate company.");
    }
  };

  return (
    <div className="max-w-md mx-auto p-4 bg-white shadow-lg rounded-lg">
      <h2 className="text-xl font-bold mb-4">Bank Account Details</h2>

      <input
        type="text"
        name="companyId"
        placeholder="Company ID"
        value={formData.companyId}
        onChange={handleChange}
        className="w-full p-2 mb-2 border rounded"
      />

      <input
        type="text"
        name="accountHolderName"
        placeholder="Account Holder Name"
        value={formData.accountHolderName}
        onChange={handleChange}
        className="w-full p-2 mb-2 border rounded"
      />

      <input
        type="text"
        name="ifscCode"
        placeholder="IFSC Code"
        value={formData.ifscCode}
        onChange={handleChange}
        className="w-full p-2 mb-2 border rounded"
      />

      <input
        type="text"
        name="accountNumber"
        placeholder="Account Number"
        value={formData.accountNumber}
        onChange={handleChange}
        className="w-full p-2 mb-2 border rounded"
      />

      <input
        type="text"
        name="accountNumberDoubleConfirmation"
        placeholder="Confirm Account Number"
        value={formData.accountNumberDoubleConfirmation}
        onChange={handleChange}
        className="w-full p-2 mb-4 border rounded"
      />

      <button
        onClick={handleAddBankAccount}
        disabled={loading}
        className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
      >
        {loading ? "Processing..." : "Add Bank Account"}
      </button>

      <button
        onClick={handleActivateBankAccount}
        disabled={loading || !bankDetails}
        className="w-full bg-green-500 text-white py-2 mt-4 rounded hover:bg-green-600"
      >
        {loading ? "Processing..." : "Activate Bank Account"}
      </button>

      <button
        onClick={handleActivateCompany}
        disabled={loading || !formData.companyId}
        className="w-full bg-green-500 text-white py-2 mt-4 rounded hover:bg-green-600"
      >
        {loading ? "Processing..." : "Activate Company"}
      </button>

      {message && <p className="mt-4 text-center">{message}</p>}

      {/* JSON Response View */}
      {responseData && (
        <div className="mt-6 bg-gray-100 p-4 rounded-lg shadow-md">
          <h2 className="text-lg font-semibold mb-2">API Response:</h2>
          <pre className="text-sm text-gray-800 bg-gray-200 p-2 rounded-md overflow-auto">
            {JSON.stringify(responseData, null, 2)}
          </pre>
        </div>
      )}
    </div>
  );
};

export default AddBank;
