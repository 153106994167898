import React, { useEffect, useState } from 'react';
import { createMoneyBasedPlan, fetchPlan, activatePlan } from '../../api';

const CreateMoneyPlan = () => {
  const [formData, setFormData] = useState({
    inrUnit: '',
    planName: '',
    entityType: '',
    moneyFlowType: '',
    planCustomizationType: '',
    revenueCalculationQuantityType: '',
    type: 'MONEY',
    modelType: '',
    calculationType: '',
    value: '',
    settlementSchedule: '',
  });

  const [dropdownOptions, setDropdownOptions] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [planId, setPlanId] = useState(null); 
  const [responseData, setResponseData] = useState(null);

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      try {
        const data = await fetchPlan('money');
        const options = {
          inrUnit: data.inrUnitOptions || [],
          entityType: data.entityTypeOptions || [],
          moneyFlowType: data.moneyFlowTypeOptions || [],
          planCustomizationType: data.planCustomizationTypeOptions || [],
          revenueCalculationQuantityType: data.revenueCalculationQuantityTypeOptions || [],
          modelType: data.revenueModelTypeOptions || [],
          calculationType: data.revenueSharingCalculationTypeOptions || [],
          settlementSchedule: data.settlementScheduleOptions || [],
        };
        setDropdownOptions(options);
      } catch (err) {
        console.error('Error fetching dropdown options:', err);
        setError('Failed to fetch dropdown options.');
      }
    };

    fetchDropdownOptions();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name === 'value' ? Number(value) : value,
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);

    try {
      const res = await createMoneyBasedPlan(formData);
      console.log('API Response:', res);
      setPlanId(res.planId);
      setResponseData(res); 
      alert('Money-based plan created successfully!');
    } catch (err) {
      console.error('API Error:', err);
      setError('Failed to create the plan. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleActivatePlan = async () => {
    if (!planId) {
      setError('No plan created yet. Please create a plan first.');
      return;
    }
    setLoading(true);
    setError(null);

    try {
      const res = await activatePlan(planId);
      setResponseData(res); 
      alert('Plan activated successfully!');
    } catch (err) {
      console.error('API Error:', err);
      setError('Failed to activate the plan. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-white shadow-lg rounded-lg p-8 w-full">
      <h1 className="text-2xl font-bold text-center text-gray-800 mb-6">Create Money-Based Plan</h1>
      <form className="space-y-4">
        {Object.keys(formData).map((key) => (
          <div key={key}>
            <label htmlFor={key} className="block text-gray-700 font-medium mb-2 capitalize">
              {key.replace(/([A-Z])/g, ' $1')}
            </label>
            {dropdownOptions[key] ? (
              <select
                id={key}
                name={key}
                value={formData[key] || ''}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded-lg px-4 py-2 text-gray-400"
              >
                <option value="" disabled hidden className="text-gray-400">
                  Select {key.replace(/([A-Z])/g, ' $1')}
                </option>
                {dropdownOptions[key]?.map((option) => (
                  <option key={option} value={option} className="text-gray-400">
                    {option}
                  </option>
                ))}
              </select>
            ) : (
              <input
                type={key === 'value' ? 'number' : 'text'}
                id={key}
                name={key}
                value={formData[key]}
                placeholder={`Enter ${key.replace(/([A-Z])/g, ' $1')}`}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:ring focus:ring-blue-200"
              />
            )}
          </div>
        ))}
      </form>
      <button
        onClick={handleSubmit}
        className="bg-blue-500 text-white w-full py-2 rounded-lg hover:bg-blue-600 mt-4 focus:outline-none focus:ring focus:ring-blue-300"
        disabled={loading}
      >
        {loading ? 'Submitting...' : 'Create Plan'}
      </button>
      {error && <p className="text-center text-red-500 mt-4">{error}</p>}

      {/* Conditionally render the Activate Plan button only if planId is available */}
      {planId && (
        <button
          onClick={handleActivatePlan}
          className="bg-green-500 text-white w-full py-2 rounded-lg hover:bg-green-600 mt-4 focus:outline-none focus:ring focus:ring-green-300"
          disabled={loading}
        >
          {loading ? 'Activating...' : 'Activate Plan'}
        </button>
      )}

      {/* JSON Response View */}
      {responseData && (
        <div className="mt-6 bg-gray-100 p-4 rounded-lg shadow-md">
          <h2 className="text-lg font-semibold mb-2">API Response:</h2>
          <pre className="text-sm text-gray-800 bg-gray-200 p-2 rounded-md overflow-auto">
            {JSON.stringify(responseData, null, 2)}
          </pre>
        </div>
      )}
    </div>
  );
};

export default CreateMoneyPlan;
