import React, { useEffect, useState } from 'react';
import { getBookingStatsTillDate } from '../../api';

const BookingStatsComponent = () => {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchStats = async () => {
    setLoading(true);
    try {
      const data = await getBookingStatsTillDate();
      setStats(data);
      setError(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStats();
  }, []);

  if (loading) return <p className="text-center text-lg font-semibold">Loading booking stats...</p>;
  if (error) return <p className="text-center text-red-500 font-semibold">Error: {error}</p>;

  return (
    <div className="max-w-xl mx-auto p-6 bg-white shadow-lg rounded-lg">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">Booking Stats</h2>
        <button
          onClick={fetchStats}
          className="px-4 py-2 text-white bg-green-500 hover:bg-green-600 rounded-lg transition duration-300"
        >
          Refresh Stats
        </button>
      </div>

      {stats && (
        <div className="grid gap-4">
          {Object.entries(stats).map(([key, value]) => (
            <div key={key} className="bg-gray-50 p-4 rounded-lg">
              <h3 className="text-lg font-semibold capitalize mb-2">
                {key.replace(/_/g, ' ')}
              </h3>
              {Array.isArray(value) ? (
                <div className="grid gap-2">
                  {value.map((item, index) => (
                    <p key={index} className="text-lg font-medium text-blue-600">
                      {item}
                    </p>
                  ))}
                </div>
              ) : (
                <p className="text-2xl font-bold text-blue-600">
                  {typeof value === 'number' ? value.toLocaleString() : value}
                </p>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BookingStatsComponent;
