import React, { useEffect, useState } from "react";
import { createOperator, fetchOperatorOptions } from "../../api";

const AddOperatorForm = () => {
  const [sizeOptions, setSizeOptions] = useState([]);
  const [networkOptions, setNetworkOptions] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [showResponse, setShowResponse] = useState(false);
  const [formData, setFormData] = useState({
    companyId: "",
    name: "",
    size: "",
    verifiedNetwork: "",
    logoUrl: "",
    operatorUEIId: "",
    yearActiveSince: "",
  });

  useEffect(() => {
    const loadOptions = async () => {
      try {
        const response = await fetchOperatorOptions();
        setSizeOptions(response.sizeOptions || []);
        setNetworkOptions(response.networkOptions || []);
      } catch (error) {
        console.error("Error loading options:", error);
      }
    };
    loadOptions();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowResponse(false);
    try {
      const response = await createOperator(formData);
      setResponseData(response)
      setShowResponse(true);
      alert("Operator Created Successfully!");
    } catch (error) {
      alert("Failed to create operator.");
    }
  };

  return (
    <div className="max-w-lg mx-auto p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold text-center mb-6">Add New Operator</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Company ID:</label>
          <input
            type="text"
            name="companyId"
            value={formData.companyId}
            onChange={handleChange}
            className="border p-3 w-full rounded-lg"
            placeholder="Enter Company ID"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Operator Name:</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="border p-3 w-full rounded-lg"
            placeholder="Enter Operator Name"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Select Size:</label>
          <select
            name="size"
            value={formData.size}
            onChange={handleChange}
            className="border p-3 w-full rounded-lg"
          >
            <option value="">Select Size</option>
            {sizeOptions.map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Select Network:</label>
          <select
            name="verifiedNetwork"
            value={formData.verifiedNetwork}
            onChange={handleChange}
            className="border p-3 w-full rounded-lg"
          >
            <option value="">Select Network</option>
            {networkOptions.map((network) => (
              <option key={network} value={network}>
                {network}
              </option>
            ))}
          </select>
        </div>

        <button
          type="submit"
          className="w-full py-3 mt-4 bg-blue-600 text-white rounded-lg font-semibold hover:bg-blue-700 transition duration-200"
        >
          Submit
        </button>

        {/* JSON Response View */}
        {showResponse && responseData && (
          <div className="mt-6 bg-gray-100 p-4 rounded-lg shadow-md">
            <h2 className="text-lg font-semibold mb-2">API Response:</h2>
            <pre className="text-sm text-gray-800 bg-gray-200 p-2 rounded-md overflow-auto">
              {JSON.stringify(responseData, null, 2)}
            </pre>
          </div>
        )}
      </form>
    </div>
  );
};

export default AddOperatorForm;
