import React, { useState } from 'react';
import { stopChargeAdmin } from '../../api';

const eventTypes = [
    { label: "Internal Suspected Abuse", value: "INTERNAL_SUSPECTED_ABUSE" },
];

const StopCharger = () => {
  const [chargingStationId, setChargingStationId] = useState("");
  const [eventType, setEventType] = useState("");
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleCheckStatus = async () => {
    setLoading(true);
    setError(null);
    setStatus(null);

    try {
      const response = await stopChargeAdmin({
        chargingStationId,
        eventType: eventType
      });
      setStatus(response);
    } catch (err) {
      setError("Failed to check charger status.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen p-4">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-xl font-semibold text-gray-800 mb-4">Stop Charger</h2>

        <div className="mb-4">
          <label className="block text-gray-600 text-sm font-medium">Charging Station ID</label>
          <input
            type="text"
            placeholder="Enter Charging Station ID"
            value={chargingStationId}
            onChange={(e) => setChargingStationId(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-0 focus:border-blue-600"
          />
        </div>
        
        {/* Event Type Dropdown */}
        <div className="mb-4">
          <label className="block text-gray-600 text-sm font-medium">
            Event Type
          </label>
          <select
            value={eventType}
            onChange={(e) => setEventType(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-0 focus:border-blue-600"
          >
            <option value="">Select Event Type</option>
            {eventTypes.map((event) => (
              <option key={event.value} value={event.value}>
                {event.label}
              </option>
            ))}
          </select>
        </div>

        <button
          onClick={handleCheckStatus}
          disabled={loading}
          className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-indigo-700 transition"
        >
          {loading ? "Checking..." : "Check Status"}
        </button>

        {status && (
          <div className="mt-4 p-3 bg-gray-100 text-green-800 rounded-lg">
            <strong>Status:</strong>
            <pre className="bg-gray-200 p-2 rounded-md text-sm overflow-x-auto">
              {JSON.stringify(status, null, 2)}
            </pre>
          </div>
        )}

        {error && (
          <div className="mt-4 p-3 bg-red-100 text-red-800 rounded-lg">
            {error}
          </div>
        )}
      </div>
    </div>
  );
};


export default StopCharger;

