import adminApiInstance from './adminInstance';
import apiInstance from './instance';
import endpoints from './endpoints/endpoints'

// Function to handle login
export const loginUser = async ({ email, password }) => {
  try {
    const response = await apiInstance({
      endpoint: "/internaluser/auth/login",
      method: "POST",
      body: {
        mode: "EMAIL_PASSWORD",
        email,
        password,
        userType: "INTERNAL_USER",
      },
    });

    return response;
  } catch (error) {
    throw error;
  }
};

// Function to add a new vehicle model
export const addVehicleModel = async (payload) => {
  try {
    const response = await apiInstance({
      endpoint: '/model',
      method: 'POST',
      body: payload,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Function to fetch booking details by ID
export const getBookingDetails = async (bookingId) => {
  try {
    const response = await apiInstance({
      endpoint: `/internaluser/booking/${bookingId}/details`,
      method: 'GET',
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Function to create a business user
export const createBusinessUser = async (formData) => {
  try {
    const response = await apiInstance({
      endpoint: '/internaluser/auth/business-user-creation',
      method: 'POST',
      body: formData,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Function to fetch models
export const fetchModels = async () => {
  try {
    const response = await apiInstance({
      endpoint: '/models/list',
      method: 'POST',
      body: {
        wheelsizeRestriction: [],
        vendorRestriction: [],
      },
    });
    return response.models;
  } catch (error) {
    throw new Error('Error fetching models: ' + error.message);
  }
};

// Function to resolve QR code
export const resolveQR = async (chargerId) => {
  try {
    const response = await apiInstance({
      endpoint: '/internaluser/qr/resolve',
      method: 'POST',
      body: {
        qrText: `chargepe://charge?cid=${chargerId}`,
      },
    });
    return response;
  } catch (error) {
    throw new Error('Error resolving QR code: ' + error.message);
  }
};

// New function to fetch migration request details by request ID
export const getMigrationDetails = async (requestId) => {
  try {
    const response = await apiInstance({
      endpoint: `/store/pg/migration/${requestId}/details`,
      method: 'GET',
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// For verify migration request
export const verifyMigration = async (requestId) => {
  try {
    const response = await apiInstance({
      endpoint: endpoints.verifyMigration(requestId),
      method: 'PUT',
    });
    return response;
  } catch (error) {
    return false;
  }
};

// For cleanup migration request
export const cleanupMigration = async (requestId) => {
  try {
    const response = await apiInstance({
      endpoint: endpoints.cleanupMigration(requestId),
      method: 'PUT',
    });
    return response;
  } catch (error) {
    return false;
  }
};

// For get all operator
export const getAllOperators = async () => {
  try{
    const response = await apiInstance({
      endpoint: endpoints.getAllOperators,
    method: 'GET',
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// For operator details
export const getOperatorDetails = async (operatorId) => {
  try {
    const response = await apiInstance({
      endpoint: endpoints.getOperatorDetails(operatorId),
      method: 'GET',
    });
    return response;
  } catch (error) {
    return false;
  }
};


export const fetchStoreDetails = async (storeId) => {
  try {
    const response = await apiInstance({
      endpoint: `/internaluser/store/${storeId}/details`,
      method: 'GET',
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Functions from InternalStoresSearch component
export const fetchStores = async (location, states, page, pageSize) => {
  try {
    const response = await apiInstance({
      endpoint: '/internaluser/stores/list',
      method: 'POST',
      body: {
        latLng: {
          latitude: location.latitude,
          longitude: location.longitude,
        },
        radiusMeters: 50000,
        states,
        pageRequest: {
          pageNumber: page,
          pageSize,
        },
      },
    });
    return response.storeContexts;
  } catch (error) {
    throw error;
  }
};

export const updateStore = async (storeId, updatedStoreData) => {
  try {
    const response = await apiInstance({
      endpoint: `/internaluser/store/${storeId}`,
      method: 'PUT',
      body: updatedStoreData,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Functions from StoreDetailsEditor component
export const approveImage = async (storeId, imageId) => {
  try {
    const response = await apiInstance({
      endpoint: `/store/${storeId}/${imageId}/approve`,
      method: 'PUT',
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const rejectImage = async (storeId, imageId) => {
  try {
    const response = await apiInstance({
      endpoint: `/store/${storeId}/${imageId}/reject`,
      method: 'PUT',
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateStoreData = async (storeId, updatedStoreData) => {
  try {
    const response = await apiInstance({
      endpoint: `/internaluser/store/${storeId}`,
      method: 'PUT',
      body: updatedStoreData,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Location suggestions
export const fetchLocationSuggestions = async (query, setSuggestions) => {
  try {
    const response = await apiInstance({
      endpoint: endpoints.fetchLocationSuggestions1(query),
        method: 'GET',
      }
    )    
    setSuggestions(response.predictions);
    return response;
  } catch (error) {
    setSuggestions([]);
  }
};

// healthCheck api
export const fetchHealthCheck = async () => {
  try {
    const response = await adminApiInstance({
      endpoint: endpoints.fetchHealthCheck,
      method: 'GET',
    });
    return response;
  } catch (error) {
  }
};

// Function to fetch Default Store Options
export const getDefaultStoreOptions = async () => {
  try {
    const response = await apiInstance({
      endpoint: '/internaluser/store/default-add-store-request',
      method: 'GET',
    });
    return response;
  } catch (error) {
    throw error;
  }
};


// Function to create a New Store
export const createNewStore = async (formData) => {
  try {
    const response = await apiInstance({
      endpoint: '/internaluser/store/init',
      method: 'POST',
      body: formData,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

//Function To Fetch Enums for Store Creation
export const fetchEnums = async () => {
  try {
    const response = await apiInstance({
        endpoint:'/internaluser/store/write-request-creation-data',
        method:'GET'
    });
    return response;
  } catch (error) {
    throw error;
  }
};

//Function To Fetch Connector Type
export const fetchConnectorTypes = async () => {
  try {
    const response = await apiInstance({
      endpoint: "/connector-types",
      method: 'GET',
    });
    return response;
  } catch (error) {
    throw error;
  }
};

//Function to Add Charging Stations
export const AddChargingStation = async (formData) => {
  try {
    const response = await apiInstance({
      endpoint: "/internaluser/charging-station",
      method: 'POST',
      body: formData,
    });
    if (response) {
      return response;
    }
  } catch (error) {
    throw error;
  }
};


// Function to get a presigned URL for image upload
export const getPresignedUrl = async ( interactionType,interactionId,storeId ) => {
  try {
    const response = await apiInstance({
      endpoint: "/internaluser/store/add-image/pre-auth",
      method: "POST",
      body: {
        interactionType: interactionType,
        interactionId: interactionId,
        storeId: storeId,
        imageFormat: "JPG",
      },
     
    });
    return response;
  } catch (error) {
    throw new Error("Failed to get upload URL");
  }
};

// Function to verify image upload
export const verifyUpload = async (storeId, imageId) => {
  try {
    const response = await apiInstance({
      endpoint: `/store/${storeId}/${imageId}/verify-upload`,
      method: 'PUT',
    });
    return response;
  } catch (error) {
    throw new Error('Failed to verify upload');
  }
};

export const fetchChargerList = async (location, states, page) => {
  try {
    const response = await apiInstance({
      endpoint: "/internaluser/stores/list/complete",
      method: "POST",
      body: {
        latLng: {
          latitude: location.latitude,
          longitude: location.longitude,
        },
        radiusMeters: 50000,
        states,
        pretty: true,
        size: page,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};



export const getActiveBooking = async (payload) => {
  try{
    const response = await apiInstance({
      endpoint: endpoints.onDemandBookingActive,
    method: 'POST',
    body:payload
    });
   
    return response;
   
  } catch (error) {
    throw error;
  }
};

export const getAllBookings = async (payload) => {
  try{
    const response = await apiInstance({
      endpoint: endpoints.onDemandBookingAll,
    method: 'POST',
    body:payload
    });
   
    return response;
   
  } catch (error) {
    throw error;
  }
};

export const StartCharging = async ( bookingId, payload ) => {
  try {
    const response = await apiInstance({
      endpoint: endpoints.StartCharging(bookingId),
      method: "PUT",
      body:payload
     });
    return response;
  } catch (error) {
    throw new Error("Failed in startCharging");
  }
};

export const StopCharging = async ( bookingId, payload ) => {
  try {
    const response = await apiInstance({
      endpoint: endpoints.StopCharging(bookingId),
      method: "PUT",
      body:payload
     });
    return response;
  } catch (error) {
    throw new Error("Failed in Stop Charging");
  }
};

// Fetch on-demand bookings
export const fetchOnDemandBookings = async (location) => {
  try {
    const response = await apiInstance({
      endpoint: "/internaluser/ondemandbookings/all",
      method: "POST",
      body: {
        location: {
          city: location.city, 
          country: location.country || "INDIA", 
          state: location.state,
          latLng: {
            latitude: location.latitude,
            longitude: location.longitude,
          },
          radiusInMeters: location.radiusInMeters,
        },
      },
    });

    return response.bookings.map((booking) => ({
      id: booking.bookingId,
      latitude: booking.userLocation.latLng.latitude,
      longitude: booking.userLocation.latLng.longitude,
      description: booking.userDetails.fullName || "No description available",
      name: booking.userDetails.firstName || "Unknown User",
    }));
  } catch (error) {
    throw error;
  }
};


export const fetchPlan = async (selectedPlan) => {
  const apiEndpoints = {
    energy: '/internal/plan/energy-based/default',
    money: '/internal/plan/money-based/default',
  };
  if (!selectedPlan) {
    throw new Error('No plan selected.');
  }
  try {
    const response = await apiInstance({
      endpoint: apiEndpoints[selectedPlan],
      method: 'GET',
    });
    return response;
  } catch (error) {
    throw new Error('Failed to fetch plan.');
  }
};

export const createEnergyBasedPlan = async (formData) => {
  try {
    const response = await apiInstance({
      endpoint: '/internal/plan/energy-based',
      method: 'POST',
      body: formData,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const createMoneyBasedPlan = async (formData) => {
  try {
    const response = await apiInstance({
      endpoint: '/internal/plan/money-based',
      method: 'POST',
      body: formData,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const activatePlan = async (planId) => {
  try {
    const response = await apiInstance({
      endpoint: `/internal/plan/activate-plan/${planId}`, 
      method: 'PUT', 
      body: { planId },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const createCompany = async (formData) => {
  try {
    const response = await apiInstance({
      endpoint: `/company`,
      method: 'POST',
      body: formData,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Function to add a bank account
export const addBankAccount = async (formData) => {
  try {
    const response = await apiInstance({
      endpoint: `/company/bank-account-details`,
      method: "POST",
      body: formData,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Function to activate a bank account
export const activateBankAccount = async (entityId, bankAccountId) => {
  try {
    const response = await apiInstance({
      endpoint: `/company/${entityId}/bank-account-details/${bankAccountId}/activate`,
      method: "PUT",
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Function to activate a company
export const activateCompany = async (companyId) => {
  try {
    const response = await apiInstance({
      endpoint: `/company/${companyId}/activate`,
      method: "PUT",
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchOperatorOptions = async () => {
  try {
    const response = await apiInstance({
      endpoint: `/internaluser/operator/default-add-operator-request`,
      method: "GET",
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Create a new operator
export const createOperator = async (formData) => {
  try {
    const response = await apiInstance({
      endpoint: `/internaluser/operator`,
      method: "POST",
      body: formData,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const createPartner = async (partnerData) => {
  try {
    const response = await apiInstance({
      endpoint: `/internal/partner`,
      method: "POST",
      body: partnerData,
    });
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Failed to create partner");
  }
};

export const getEligiblePlans = async (partnerId) => {
  try {
    const response = await apiInstance({
      endpoint: `/internal/partner/${partnerId}/eligible-plans`,
      method: "GET",
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const addPlanToPartner = async (partnerId, planId) => {
  try {
    const response = await apiInstance({
      endpoint: `/internal/partner/${partnerId}/plan/${planId}/add`, 
      method: "PUT",
    });
    return response; 
  } catch (error) {
    throw error;
  }
};

export const activatePartner = async (partnerId) => {
  try {
    const response = await apiInstance({
      endpoint: `/internal/partner/activate-partner/${partnerId}`,
      method: "PUT",
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const activateOperator = async (operatorId) => {
  try {
    const response = await apiInstance({
      endpoint: `/internaluser/operator/${operatorId}/activate-operator`,
      method: "PUT",
    });
    return response;
  } catch (error) {
    throw error;
  }
};


// Function to initiate a settlement
export const initiateSettlement = async (partyType, partyId) => {
  try {
    const response = await apiInstance({
      endpoint: `/internal/settlement/${partyType}/${partyId}/init`,
      method: 'POST',
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Function to confirm a settlement
export const confirmSettlement = async (payload) => {
  try {
    const response = await apiInstance({
      endpoint: '/internal/settlement/confirm',
      method: 'POST',
      body: payload,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Function to unstage a settlement
export const unstageSettlement = async (partyType, partyId, settlementId) => {
  try {
    const response = await apiInstance({
      endpoint: `/internal/settlement/${partyType}/${partyId}/unstage-settlement/${settlementId}`,
      method: 'PUT',
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Function to fetch pending settlements dynamically by operator ID
export const getPendingSettlements = async (operatorId) => {
  try {
    const response = await apiInstance({
      endpoint: `/internaluser/operator/${operatorId}/pending-settlement-summary`,
      method: 'GET',
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Function to fetch completed settlements dynamically by operator ID
export const getCompletedSettlements = async (operatorId,  fetchWindow) => {
  try {
    const response = await apiInstance({
      endpoint: `/internaluser/operator/${operatorId}/settlements-list`,
      method: 'POST',
      body: { fetchWindow },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Function to fetch stores of an operator dynamically by operator ID
export const getOperatorStores = async (operatorId) => {
  try {
    const response = await apiInstance({
      endpoint: `/internaluser/store/operator/${operatorId}/store-list`,
      method: 'GET',
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Function to get stores by operator ID
export const getStoresByOperatorId = async (operatorId) => {
  try {
    const response = await apiInstance({
      endpoint: `/internaluser/store/operator/${operatorId}/store-list`,
      method: 'GET',
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Function to fetch operator booking list dynamically
export const getOperatorBookingList = async (operatorId, payload) => {
  try {
    const response = await apiInstance({
      endpoint: `/internaluser/operator/${operatorId}/booking-list`,
      method: 'POST',
      body: payload,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Function to create a business user dynamically
export const createBusinesUserOperator = async (formData) => {
  try {
    const response = await apiInstance({
      endpoint: `/internaluser/auth/business-user-creation`,
      method: 'POST',
      body: formData,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Function to activate a wallet
export const activateWallet = async ({ searchType, searchValue }) => {
  try {
    const response = await apiInstance({
      endpoint: `/internaluser/wallet/activate?${searchType}=${searchValue}`,
      method: "PUT",
      body: {
        walletState: "ENABLED",
        stateChangeReason: "USER_TRIGGERED",
      },
    });

    return response;
  } catch (error) {
    throw error;
  }
};


// Function to deactivate a wallet
export const deactivateWallet = async ({ searchType, searchValue }) => {
  try {
    const response = await apiInstance({
      endpoint: `/internaluser/wallet/deactivate?${searchType}=${searchValue}`,
      method: "PUT",
      body: {
        walletState: "DISABLED",
        changeReason: "USER_TRIGGERED",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getWalletDetails = async (searchType, searchValue) => {
  try {
    const response = await apiInstance({
      endpoint: `/internaluser/wallet/get-wallet?${searchType}=${searchValue}`,
      method: "GET",
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Function to check if a charger is alive
export const checkChargerAlive = async ({ chargingStationId, connectorId }) => {
  try {
    const response = await apiInstance({
      endpoint: `/internaluser/charging-station/check-charger-alive`,
      method: "POST",
      body: {
        chargingStationId,
        connectorId,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Function to check if a charger is alive
export const stopChargeAdmin = async ({ chargingStationId, eventType }) => {
  try {
    const response = await apiInstance({
      endpoint: `/internaluser/charging-station/stop-charge-admin`,
      method: "POST",
      body: {
        chargingStationId,
        eventType,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getBookingStatsTillDate = async () => {
  try {
    const response = await apiInstance({
      endpoint: '/internaluser/booking/stats-till-date',
      method: 'GET',
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Update Pricing Ruleset
export const updatePricingRuleset = async (storeId, pricingRules) => {
  try {
    const response = await apiInstance({
      endpoint: `/internaluser/store/${storeId}/pricing-ruleset`,
      method: 'PUT',
      body: pricingRules,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Function to add money to wallet
// export const addMoneyToWallet = async (payload) => {
//   try {
//     const response = await apiInstance({
//       endpoint: "/internaluser/wallet/add-money",
//       method: "PUT",
//       body: payload,
//     });
//     return response;
//   } catch (error) {
//     throw error;
//   }
// };

export const tokenOfApology = async (payload) => {
  try {
    const response = await apiInstance({
      endpoint: "/internaluser/wallet/toa",
      method: "PUT",
      body: payload,
    });
    return response;
  } catch (error) {
    throw error;
  }
};