import React, { useState } from 'react';
import { getStoresByOperatorId } from '../../api';

const OperatorStores = () => {
  const [operatorId, setOperatorId] = useState('');
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleFetchStores = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await getStoresByOperatorId(operatorId);
      setStores(response.stores || []);
    } catch (err) {
      setError('Failed to fetch stores. Please check the Operator ID.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-7xl mx-auto p-6 bg-white shadow-lg rounded-2xl">
      <h2 className="text-2xl font-semibold mb-4 text-center">Operator Store List</h2>
      
      <div className="flex flex-col sm:flex-row items-center gap-4 mb-6">
        <input
          type="text"
          placeholder="Enter Operator ID"
          value={operatorId}
          onChange={(e) => setOperatorId(e.target.value)}
          className="w-full sm:w-2/3 px-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          onClick={handleFetchStores}
          disabled={!operatorId || loading}
          className="bg-blue-600 text-white px-6 py-2 rounded-lg shadow hover:bg-blue-700 transition"
        >
          {loading ? 'Fetching...' : 'Fetch Stores'}
        </button>
      </div>

      {error && <p className="text-red-500 text-center">{error}</p>}

      {stores.length > 0 && (
        <div className="grid grid-cols-1 gap-6">
          {stores.map((store) => (
            <div key={store.storeId} className="border rounded-lg p-6 shadow-lg bg-gray-50">
              <h3 className="text-xl font-semibold mb-2">{store.name || 'Unnamed Store'}</h3>
              <p><strong>Store ID:</strong> {store.storeId}</p>
              <p><strong>Operator ID:</strong> {store.operatorId}</p>
              <p><strong>Description:</strong> {store.description || 'N/A'}</p>
              <p><strong>Location Type:</strong> {store.locationType}</p>
              <p><strong>Parking Level:</strong> {store.parkingLevel}</p>
              <p><strong>Round-the-Clock Access:</strong> {store.availableRoundTheClock ? '✅ Yes' : '❌ No'}</p>
              <p><strong>Restricted Access:</strong> {store.restrictedAccess ? '✅ Yes' : '❌ No'}</p>
              <p><strong>Year Active Since:</strong> {store.yearActiveSince}</p>

              {store.location && (
                <div className="mt-4">
                  <h4 className="text-lg font-semibold mb-1">📍 Location Details:</h4>
                  <p><strong>Address:</strong> {store.location.addressLine1}, {store.location.city}, {store.location.state}</p>
                  <p><strong>Latitude:</strong> {store.location.latLng?.latitude}</p>
                  <p><strong>Longitude:</strong> {store.location.latLng?.longitude}</p>
                  <p><strong>Radius (meters):</strong> {store.location.radiusInMeters}</p>
                </div>
              )}

              {store.contactInfo && (
                <div className="mt-4">
                  <h4 className="text-lg font-semibold mb-1">📞 Contact Info:</h4>
                  <p><strong>Primary Contact:</strong> +{store.contactInfo.primarySupportNumber.countryCode} {store.contactInfo.primarySupportNumber.phoneNumber}</p>
                </div>
              )}

              {store.images && store.images.length > 0 && (
                <div className="mt-4">
                  <h4 className="text-lg font-semibold mb-1">🖼️ Store Images:</h4>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    {store.images.map((img) => (
                      <img
                        key={img.imageId}
                        src={img.s3Url}
                        alt={store.name}
                        className="w-full h-64 object-cover rounded-lg shadow"
                      />
                    ))}
                  </div>
                </div>
              )}

              {store.amenities?.amenityTypes && (
                <div className="mt-4">
                  <h4 className="text-lg font-semibold mb-1">🛠️ Amenities:</h4>
                  <ul className="list-disc list-inside">
                    {store.amenities.amenityTypes.map((amenity, index) => (
                      <li key={index}>{amenity}</li>
                    ))}
                  </ul>
                </div>
              )}

              {store.pricingRules?.pricingRules && store.pricingRules.pricingRules.length > 0 && (
                <div className="mt-4">
                  <h4 className="text-lg font-semibold mb-1">💲 Pricing Rules:</h4>
                  {store.pricingRules.pricingRules.map((rule, index) => (
                    <div key={index} className="border p-3 rounded-lg bg-white shadow-sm mb-2">
                      <p><strong>Rule Type:</strong> {rule.ruleType}</p>
                      {rule.chargerPowerRatingToUnitPriceMap && Object.entries(rule.chargerPowerRatingToUnitPriceMap).map(([key, value]) => (
                        <div key={key}>
                          <p><strong>Power Rating:</strong> {key}</p>
                          <p><strong>Rate:</strong> {value.rate} {value.unit}</p>
                          <p><strong>Verified:</strong> {value.verified ? '✅ Yes' : '❌ No'}</p>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              )}

              {store.locationTags && store.locationTags.length > 0 && (
                <div className="mt-4">
                  <h4 className="text-lg font-semibold mb-1">🏷️ Location Tags:</h4>
                  <p>{store.locationTags.join(', ')}</p>
                </div>
              )}

              <p className="mt-4"><strong>Third Party Sourced:</strong> {store.thirdPartySourced ? '✅ Yes' : '❌ No'}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default OperatorStores;

