import React, { useEffect, useState, useCallback } from "react";
import { API_KEY } from "../constants/localStorage-keys";

const AddressInput = ({ onLocationSelect }) => {
  const [address, setAddress] = useState("");
  const [hasFetched, setHasFetched] = useState(false); 

  const fetchLatLng = useCallback(async () => {
    if (!address || hasFetched) return; 
    setHasFetched(true); 

    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${API_KEY}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.status === "OK" && data.results.length > 0) {
        const result = data.results[0];

        let city = "";
        let state = "";

        result.address_components.forEach((component) => {
          if (component.types.includes("locality")) {
            city = component.long_name.toUpperCase();
          }
          if (component.types.includes("administrative_area_level_1")) {
            state = component.long_name.toUpperCase();
          }
        });

        const locationDetails = {
          lat: result.geometry.location.lat,
          lng: result.geometry.location.lng,
          placeId: result.place_id,
          city,
          state,
          address,
        };

        onLocationSelect(locationDetails);
      } else {
      }
    } catch (error) {
      console.error("Error fetching location:", error);
    }
  }, [address, hasFetched, onLocationSelect]); // Add hasFetched as dependency

  useEffect(() => {
    if (!address) {
      setHasFetched(false); // Reset if user clears input
      return;
    }

    const debounceFetch = setTimeout(() => {
      fetchLatLng();
    }, 500); // Debounce to avoid excessive API calls

    return () => clearTimeout(debounceFetch);
  }, [address, fetchLatLng]); // Added fetchLatLng to dependency array

  return (
    <div>
      <input
        type="text"
        placeholder="Enter address..."
        value={address}
        onChange={(e) => {
          setAddress(e.target.value);
          setHasFetched(false); // Reset fetch flag when user types
        }}
        className="border p-2 w-full rounded"
      />
    </div>
  );
};

export default AddressInput;
