import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Login from "./components/Login";
import Signup from "./components/Signup";
import { AppProvider } from "./Context";
import ProtectedRoute from "./components/PrivateRoute";

function App() {
  const [isLogin, setIsLogin] = useState(true);

  const AuthPage = () => (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-md w-96">
        <h1 className="text-2xl font-bold mb-6 text-center">
          {isLogin ? "Login" : "Sign Up"}
        </h1>
        {isLogin ? <Login /> : <Signup />}
        <p className="mt-4 text-center">
          {isLogin ? "Don't have an account? " : "Already have an account? "}
          <button
            className="text-blue-500 hover:underline"
            onClick={() => setIsLogin(!isLogin)}
          >
            {isLogin ? "Sign Up" : "Login"}
          </button>
        </p>
      </div>
    </div>
  );

  return (
    <AppProvider>
      <Router>
        <Routes>
          {/* Public Auth Page */}
          <Route path="/auth" element={<AuthPage />} />

          {/* Protected HomePage (Only Accessible with Token) */}
          <Route element={<ProtectedRoute />}>
            <Route path="/*" element={<HomePage />} />
          </Route>

          {/* Redirect Root to Auth Page */}
          <Route path="/" element={<Navigate to="/auth" replace />} />
        </Routes>
      </Router>
    </AppProvider>
  );
}

export default App;

