import React, { useState } from 'react';
import { createBusinesUserOperator } from '../../api';

const CreateBusinessOperator = () => {
    const [jsonResponse, setJsonResponse] = useState(null);
    const [formData, setFormData] = useState({
        merchantId: '',
        entityId: '',
        entityType: '',
        phone: {
            countryCode: '',
            phoneNumber: '',
        },
        role: 'STORE_MANAGER',
        gender: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handlePhoneChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            phone: {
                ...prevData.phone,
                [name]: value,
            },
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await createBusinesUserOperator(formData);
            const responseData = await response.json(); // Get JSON response
            setJsonResponse(responseData); // Store response data
            alert('Business user created successfully!');
        } catch (error) {
            alert('Failed to create business user.');
        }
    };

    return (
        <div className="max-w-lg mx-auto p-6 bg-white rounded-md shadow-md">
            <h2 className="text-2xl font-semibold text-center mb-6">Add User to an Operator</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label className="block text-sm font-medium text-gray-700">Merchant ID</label>
                    <input
                        type="text"
                        name="merchantId"
                        value={formData.merchantId}
                        onChange={handleChange}
                        required
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Entity ID</label>
                    <input
                        type="text"
                        name="entityId"
                        value={formData.entityId}
                        onChange={handleChange}
                        required
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Entity Type</label>
                    <input
                        type="text"
                        name="entityType"
                        value={formData.entityType}
                        onChange={handleChange}
                        required
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Country Code</label>
                    <input
                        type="text"
                        name="countryCode"
                        value={formData.phone.countryCode}
                        onChange={handlePhoneChange}
                        required
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Phone Number</label>
                    <input
                        type="text"
                        name="phoneNumber"
                        value={formData.phone.phoneNumber}
                        onChange={handlePhoneChange}
                        required
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>

                {/* Added Gender Dropdown */}
                <div>
                    <label className="block text-sm font-medium text-gray-700">Gender</label>
                    <select
                        name="gender"
                        value={formData.gender}
                        onChange={handleChange}
                        required
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
                
                <div>
                    <label className="block text-sm font-medium text-gray-700">Role</label>
                    <select
                        name="role"
                        value={formData.role}
                        onChange={handleChange}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                        <option value="STORE_MANAGER">Store Manager</option>
                        <option value="ADMIN">Admin</option>
                        <option value="USER">User</option>
                    </select>
                </div>
                <button
                    type="submit"
                    className="w-full py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Create Business User For Operator
                </button>
            </form>

            {/* Display JSON Response */}
            {jsonResponse && (
                <div className="mt-6 p-4 bg-gray-100 rounded-md shadow-sm">
                    <h3 className="text-lg font-semibold">API Response:</h3>
                    <pre className="text-sm text-gray-700 bg-gray-200 p-2 rounded-md overflow-auto">
                        {JSON.stringify(jsonResponse, null, 2)}
                    </pre>
                </div>
            )}
        </div>
    );
};

export default CreateBusinessOperator;
