import React, { useState } from 'react';
import { getCompletedSettlements, getPendingSettlements } from '../../api';

const SettlementDetails = () => {
    const [operatorId, setOperatorId] = useState('');
    const [settlementType, setSettlementType] = useState('pending');
    const [fetchWindow, setFetchWindow] = useState('LAST_MONTH');
    const [settlementsData, setSettlementsData] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchData = async () => {
        if (!operatorId) {
            setError('Please enter an Operator ID.');
            return;
        }
        setLoading(true);
        setError(null);
        try {
            let data;
            if (settlementType === 'pending') {
                data = await getPendingSettlements(operatorId);
                setSettlementsData((prev) => ({ ...prev, pending: data }));
            } else {
                data = await getCompletedSettlements(operatorId, fetchWindow);
                setSettlementsData((prev) => ({ ...prev, completed: data }));
            }
        } catch (err) {
            setError('Failed to fetch data.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex h-screen overflow-hidden">
            <div className="flex-grow p-4 overflow-y-auto">
                <h2 className="text-xl font-semibold mb-4 text-gray-800">Settlement Details</h2>
                <div className="flex flex-col md:flex-row gap-4 mb-4">
                    <input
                        type="text"
                        placeholder="Enter Operator ID"
                        value={operatorId}
                        onChange={(e) => setOperatorId(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                    />
                    <select
                        value={fetchWindow}
                        onChange={(e) => setFetchWindow(e.target.value)}
                        className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                        disabled={settlementType === 'pending'}
                    >
                        <option value="LAST_MONTH">Last Month</option>
                        <option value="LAST_3_MONTHS">Last 3 Months</option>
                        <option value="LAST_6_MONTHS">Last 6 Months</option>
                        <option value="LAST_YEAR">Last Year</option>
                    </select>
                    <select
                        value={settlementType}
                        onChange={(e) => setSettlementType(e.target.value)}
                        className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                    >
                        <option value="pending">Pending Settlement Summary</option>
                        <option value="completed">Completed Settlements List</option>
                    </select>
                    <button
                        onClick={fetchData}
                        className="p-4 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                    >
                        Search
                    </button>
                </div>
                {loading && <p className="text-blue-500">Fetching data...</p>}
                {error && <p className="text-red-500">{error}</p>}

                {settlementsData.completed?.settlements && settlementType === 'completed' && (
                    <div className="mt-6">
                        <h3 className="text-lg font-semibold mb-2">Settlements List ({fetchWindow})</h3>
                        <div className="hidden md:block overflow-x-auto">
                            <table className="w-full text-left border border-gray-300">
                                <thead>
                                    <tr className="bg-gray-100">
                                        <th className="p-2 border">Settlement ID</th>
                                        <th className="p-2 border">Operator ID</th>
                                        <th className="p-2 border">Transactions Count</th>
                                        <th className="p-2 border">Status</th>
                                        <th className="p-2 border">Settlement Amount (INR)</th>
                                        <th className="p-2 border">Solution Provider Type</th>
                                        <th className="p-2 border">Solution Provider Name</th>
                                        <th className="p-2 border">Solution Provider TransactionId</th>
                                        <th className="p-2 border">Settlement Mode</th>
                                        <th className="p-2 border">UTR Number</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {settlementsData.completed?.settlements.map((settlement) => (
                                        <tr key={settlement.settlementId} className="hover:bg-gray-50">
                                            <td className="p-2 border">{settlement.settlementId}</td>
                                            <td className="p-2 border">{settlement.operatorId}</td>
                                            <td className="p-2 border">{settlement.transactionsCount}</td>
                                            <td className="p-2 border">{settlement.settlementStatus}</td>
                                            <td className="p-2 border">{settlement.settlementAmountINR}</td>
                                            <td className="p-2 border">{settlement.solutionProviderType}</td>
                                            <td className="p-2 border">{settlement.solutionProviderName}</td>
                                            <td className="p-2 border">{settlement.solutionProviderTransactionId}</td>
                                            <td className="p-2 border">{settlement.settlementMode}</td>
                                            <td className="p-2 border">{settlement.utrNumber}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="md:hidden">
                            {settlementsData.completed.settlements.map((settlement) => (
                                <div key={settlement.settlementId} className="border p-4 mb-4 rounded-md shadow-md bg-white">
                                    <p><span className="font-bold">Settlement ID:</span> {settlement.settlementId}</p>
                                    <p><span className="font-bold">Operator ID:</span> {settlement.operatorId}</p>
                                    <p><span className="font-bold">Transactions Count:</span> {settlement.transactionsCount}</p>
                                    <p><span className="font-bold">Status:</span> {settlement.settlementStatus}</p>
                                    <p><span className="font-bold">Settlement Amount (INR):</span> {settlement.settlementAmountINR}</p>
                                    <p><span className="font-bold">Solution Provider Type:</span> {settlement.solutionProviderType}</p>
                                    <p><span className="font-bold">Solution Provider Name:</span> {settlement.solutionProviderName}</p>
                                    <p><span className="font-bold">Solution Provider TransactionId:</span> {settlement.solutionProviderTransactionId}</p>
                                    <p><span className="font-bold">Settlement Mode:</span> {settlement.settlementMode}</p>
                                    <p><span className="font-bold">UTR Number:</span> {settlement.utrNumber}</p>
                                </div>
                            ))}
                        </div>
                        <div className="mt-4">
                            <p className="font-semibold">Total Settlements: {settlementsData.completed?.count}</p>
                            <p className="font-semibold">Total Settled Amount (INR): {settlementsData.completed?.totalSettledAmountINR}</p>
                        </div>
                    </div>
                )}
                {settlementsData.pending && settlementType === 'pending' && (
                    <div className="mt-6">
                        <h3 className="text-lg font-semibold mb-2">Pending Settlements</h3>
                        {Object.entries(settlementsData.pending).map(([key, value]) => (
                            <p key={key} className="text-gray-700 mb-1">
                                <span className="font-semibold capitalize">{key.replace(/([A-Z])/g, ' $1')}:</span> {value}
                            </p>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default SettlementDetails;
