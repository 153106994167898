import { useState, useEffect } from "react";
import { updatePricingRuleset, getDefaultStoreOptions } from "../api";

const UpdatePricing = () => {
  const [storeId, setStoreId] = useState("");
  const [pricingRule, setPricingRule] = useState({
    ruleType: "BASE_PRICE",
    chargingOperationsEntityType: "STORE",
    chargerPowerRatingToUnitPriceMap: {}
  });
  const [powerRatingOptions, setPowerRatingOptions] = useState([]);
  const [apiResponse, setApiResponse] = useState(null);

  // Options for dropdowns
  const options = {
    ruleTypeOptions: ["BASE_PRICE"],
    chargingEntityOptions: ["STORE"],
  };

  // Fetch power rating options when component mounts
  useEffect(() => {
    const fetchPowerRatingOptions = async () => {
      try {
        const response = await getDefaultStoreOptions();
        if (response?.chargerPowerRatingDenominationOptions?.[0]) {
          setPowerRatingOptions(response?.chargerPowerRatingDenominationOptions[0]);
        }
      } catch (error) {
        console.error("Failed to fetch power rating options:", error);
      }
    };

    fetchPowerRatingOptions();
  }, []);

  const handlePricingRuleChange = (field, value) => {
    setPricingRule(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handlePowerRatingChange = (ratingKey, field, value) => {
    setPricingRule(prev => ({
      ...prev,
      chargerPowerRatingToUnitPriceMap: {
        ...prev.chargerPowerRatingToUnitPriceMap,
        [ratingKey]: {
          ...prev.chargerPowerRatingToUnitPriceMap[ratingKey],
          [field]: value 
        }
      }
    }));
  };

  const handlePowerRatingSelection = (e) => {
    const rating = e.target.value;
    if (!rating) return;

    setPricingRule(prev => ({
      ...prev,
      chargerPowerRatingToUnitPriceMap: {
        ...prev.chargerPowerRatingToUnitPriceMap,
        [rating]: { rate: "", unit: "KWH" }
      }
    }));
  };

  const handleUpdatePricing = async () => {
    try {
      const formattedPricingRule = {
        ...pricingRule,
        chargerPowerRatingToUnitPriceMap: Object.entries(pricingRule.chargerPowerRatingToUnitPriceMap)
          .reduce((acc, [key, value]) => ({
            ...acc,
            [key]: {
              ...value,
              rate: value.rate === "" ? "" : Number(value.rate)
            }
          }), {})
      };

      const payload = {
        pricingRules: [formattedPricingRule]
      };
      
      const response = await updatePricingRuleset(storeId, payload);
      setApiResponse(response);
    } catch (error) {
      console.error("Failed to update pricing:", error);
      setApiResponse(error);
    }
  };

  return (
    <div className="p-6 bg-white shadow-lg rounded-lg">
      <h1 className="text-2xl font-bold mb-6 text-gray-800">Update Price for Store</h1>
      
      <div className="mb-4">
        <label htmlFor="storeId" className="block text-sm font-medium text-gray-700 mb-1">
          Store ID
        </label>
        <input
          id="storeId"
          type="text"
          placeholder="Enter Store ID"
          value={storeId}
          onChange={(e) => setStoreId(e.target.value)}
          className="border p-2 rounded w-full"
        />
      </div>

      <div className="mb-4">
        <label className="block font-semibold">Rule Type:</label>
        <select
          name="ruleType"
          value={pricingRule.ruleType}
          onChange={(e) => handlePricingRuleChange("ruleType", e.target.value)}
          className="border p-2 rounded w-full"
        >
          {options.ruleTypeOptions.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>
      </div>

      <div className="mb-4">
        <label className="block font-semibold">
          Charging Operations Entity Type:
        </label>
        <select
          name="chargingOperationsEntityType"
          value={pricingRule.chargingOperationsEntityType}
          onChange={(e) => handlePricingRuleChange("chargingOperationsEntityType", e.target.value)}
          className="border p-2 rounded w-full"
        >
          {options.chargingEntityOptions.map((entity) => (
            <option key={entity} value={entity}>
              {entity}
            </option>
          ))}
        </select>
      </div>

      <div className="mb-4">
        <label className="block font-semibold">Select Power Rating:</label>
        <select
          onChange={handlePowerRatingSelection}
          className="border p-2 rounded w-full"
        >
          <option value="">Select Power Rating</option>
          {powerRatingOptions.map((rating) => (
            <option key={rating} value={rating}>
              {rating}
            </option>
          ))}
        </select>
      </div>

      <div className="mb-4">
        {Object.entries(pricingRule.chargerPowerRatingToUnitPriceMap).map(([ratingKey, pricing]) => (
          <div key={ratingKey} className="mb-3 p-3 border rounded-lg">
            <p className="font-medium">{ratingKey}</p>
            <label className="block text-sm">Rate:</label>
            <input
              type="text"
              value={pricing.rate}
              onChange={(e) => handlePowerRatingChange(ratingKey, "rate", e.target.value)}
              className="border p-2 rounded w-full"
            />

            <label className="block text-sm mt-2">Unit:</label>
            <input
              type="text"
              value={pricing.unit}
              onChange={(e) => handlePowerRatingChange(ratingKey, "unit", e.target.value)}
              className="border p-2 rounded w-full"
            />
          </div>
        ))}
      </div>

      {apiResponse && (
        <div className="mb-4">
          <h3 className="font-semibold mb-2">Response:</h3>
          <pre className="bg-gray-100 p-4 rounded-lg overflow-auto max-h-60 text-sm">
            {JSON.stringify(apiResponse, null, 2)}
          </pre>
        </div>
      )}

      <button
        onClick={handleUpdatePricing}
        className="bg-blue-500 text-white px-4 py-2 rounded"
      >
        Update Pricing Rules
      </button>
    </div>
  );
};

export default UpdatePricing;
