import { Navigate, Outlet } from "react-router-dom";
import { AuthService } from "../AuthService";

const ProtectedRoute = () => {
  const token = AuthService.getToken(); // Use the token from AuthService

  return token ? <Outlet /> : <Navigate to="/auth" replace />; // Redirect if no token
};

export default ProtectedRoute;
