import React, { useEffect, useState } from "react";
import { Route, Routes} from "react-router-dom";
import Sidebar from "../components/Sidebar";
import CreateBusiness from "./CreateBusiness";
import QRResolve from "./QRResolve";
import BookingDetails from "./BookingDetails";
import AddVehicle from "./AddVehicle";
import ModelsList from "./ModelList";
import Migration from "./Migration";
import StoreDetails from "./StoreDetails";
import Operator from "./Operator";
import InternalStoresSearch from "./StoreSearch";
import AddStore from "./AddStore";
import PlacesAutocomplete from "../components/PlacesAutocomplete";
import AddOutlet from "./AddOutlet";
import ImageUpload from "./ImageUpload";
import { AppProvider } from "../Context";
import HealthCheck from "./HealthCheck";
import ChargingStationsMap from "./ChargingStationsMap";
import StoreList from "../components/StoreList";
import OnDemand from "./Ondemand/OnDemand";
import { ROUTES } from "../routes";
import StartCharging from "./Ondemand/StartCharging";
import ChargingDetails from "./Ondemand/ChargingDetails";
import LocationOnMap from "./locationMap";
import CreateEnergyPlan from "./Plan/CreateEnergyPlan";
import CreateMoneyPlan from "./Plan/CreateMoneyPlan";
import CreatePartner from "./Partner/CreatePartner";
import CreateOperator from "./Company/CreateOperator";
import AddBank from "./Company/AddBank";
import PartnerManagement from "./Partner/PartnerManagement";
import PlanSelector from "./Plan/PlanSelector";
import CreateCompany from "./Company/CreateCompany";
import InitiateSettlement from "./Settlement/initiateSettlement";
import SettlementDetails from "./Settlement/SettlementDetails";
import OperatorStores from "./Operator/OperatorStores";
import OperatorDetails from "./Operator/OperatorDetails";
import CreateBusinessOperator from "./Operator/CreateBussinessOperator";
import WalletActivation from "./Wallet/WalletActivation";
import WalletDeactivation from "./Wallet/WalletDeactivation";
import ChargerAlive from "./Charger_Status/ChargerAlive";
import GetWallet from "./Wallet/GetWallet";
import StopCharger from "./Charger_Status/StopChargeAdmin";
import { checkLocationPermission } from "../utils/location";
import BookingStatsComponent from "./Analytics/BookingStatsComponent";
import UpdatePricing from "./UpdatePriceStore";
import TokenOfApology from "./Wallet/TokenOfApology";
// import AddMoneyWallet from "./Wallet/AddMoneyWallet";

const HomePage = () => {
  const [ , setCoordinates] = useState({ latitude: null, longitude: null });

  useEffect(() => {
    checkLocationPermission(setCoordinates);
  }, []);

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-1 p-6 md:ml-64">
        <AppProvider>
        <Routes>
            <Route path={ROUTES.CREATE_BUSINESS} element={<CreateBusiness />} />
            <Route path={ROUTES.QR_RESOLVE} element={<QRResolve />} />
            <Route path={ROUTES.BOOKING_DETAILS} element={<BookingDetails />} />
            <Route path={ROUTES.ADD_VEHICLE} element={<AddVehicle />} />
            <Route path={ROUTES.MODEL_LIST} element={<ModelsList />} />
            <Route path={ROUTES.MIGRATION} element={<Migration />} />
            <Route path={ROUTES.STORE_DETAILS} element={<StoreDetails />} />
            <Route path={ROUTES.STORE_LIST} element={<StoreList />} />
            <Route path={ROUTES.STORE_SEARCH} element={<InternalStoresSearch />} />
            <Route path={ROUTES.OPERATOR} element={<Operator />} />
            <Route path={ROUTES.ADD_STORE} element={<AddStore />} />
            <Route path={ROUTES.PLACES} element={<PlacesAutocomplete />} />
            <Route path={ROUTES.OUTLET} element={<AddOutlet />} />
            <Route path={ROUTES.IMAGE} element={<ImageUpload />} />
            <Route path={ROUTES.HEALTH_CHECK} element={<HealthCheck />} />
            <Route path={ROUTES.CHARGING_STATION} element={<ChargingStationsMap/>} />
            <Route path={ROUTES.ONDEMAND_CHARGING} element={<OnDemand />} />
            <Route path={ROUTES.START_CHARGING} element={<StartCharging/>} />
            <Route path={ROUTES.CHARGING_DETAILS} element={<ChargingDetails/>} />
            <Route path={ROUTES.LOCATION_ONMAP} element={<LocationOnMap/>} />
            <Route path={ROUTES.PLAN_SELECTOR} element={<PlanSelector/>} /> 
            <Route path={ROUTES.CREATE_ENERGY_PLAN} element={<CreateEnergyPlan/>} /> 
            <Route path={ROUTES.CREATE_MONEY_PLAN} element={<CreateMoneyPlan/>} />
            <Route path={ROUTES.CREATE_PATNER} element={<CreatePartner/>} />
            <Route path={ROUTES.CREATE_OPERATOR} element={<CreateOperator/>} /> 
            <Route path={ROUTES.ADD_BANK} element={<AddBank/>} />
            <Route path={ROUTES.PARTNER_MAN} element={<PartnerManagement />} />
            <Route path={ROUTES.CREATE_COMPANY} element={<CreateCompany />} />
            <Route path={ROUTES.SETTLEMENT} element={<InitiateSettlement />} />
            <Route path={ROUTES.SETTLEMENT_DETAILS} element={<SettlementDetails />} />
            <Route path={ROUTES.OPERATOR_STORES} element={<OperatorStores />} />
            <Route path={ROUTES.OPERATOR_DETAILS} element={<OperatorDetails/>} />
            <Route path={ROUTES.CREATE_BUSINESS_OPERATOR} element={<CreateBusinessOperator/>} />
            <Route path={ROUTES.WALLET_ACTIVATE} element={<WalletActivation/>} />
            <Route path={ROUTES.WALLET_DEACTIVATE} element={<WalletDeactivation/>} />
            <Route path={ROUTES.WALLET_INFO} element={<GetWallet/>} />
            <Route path={ROUTES.CHARGER_ALIVE} element={<ChargerAlive/>} />
            <Route path={ROUTES.STOP_CHARGE_ADMIN} element={<StopCharger/>} />
            <Route path={ROUTES.BOOKING_STATS} element={<BookingStatsComponent/>} />
            <Route path={ROUTES.UPDATE_PRICING} element={<UpdatePricing/>} />
            {/* <Route path={ROUTES.ADD_MONEY_TO_WALLET} element={<AddMoneyWallet/>} /> */}
            <Route path={ROUTES.TOKEN_OF_APOLOGY} element={<TokenOfApology/>} />
          </Routes>
        </AppProvider>
      </div>
    </div>
  );
};

export default HomePage;
