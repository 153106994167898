import React, { useState } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import InfoWindowMap from "./InfoWindowMap";
import { fetchOnDemandBookings } from "../api"; // Import API function

const UserLocationMap = () => {
  const [location, setLocation] = useState({
    latitude: "",
    longitude: "",
    radiusInMeters: 5000,
  });
  const [chargingLocations, setChargingLocations] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFetchBookings = async () => {
    setLoading(true);
    try {
      const locations = await fetchOnDemandBookings(location);
      setChargingLocations(locations);
    } catch (error) {
      console.error("Failed to fetch bookings:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleFetchBookings();
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  if (!isLoaded) {
    return <p>Loading map...</p>;
  }

  return (
    <div>
      <div className="p-4">
        <h1 className="text-2xl font-bold mb-4">User Location on Map</h1>
        <form onSubmit={handleFormSubmit} className="mb-4">
          <div className="mb-4">
            <label className="block font-medium mb-2">Latitude</label>
            <input
              type="text"
              className="border rounded px-3 py-2 w-full"
              value={location.latitude}
              onChange={(e) =>
                setLocation((prev) => ({ ...prev, latitude: e.target.value }))
              }
            />
          </div>
          <div className="mb-4">
            <label className="block font-medium mb-2">Longitude</label>
            <input
              type="text"
              className="border rounded px-3 py-2 w-full"
              value={location.longitude}
              onChange={(e) =>
                setLocation((prev) => ({ ...prev, longitude: e.target.value }))
              }
            />
          </div>
          <div className="mb-4">
            <label className="block font-medium mb-2">Radius in Meters</label>
            <input
              type="number"
              className="border rounded px-3 py-2 w-full"
              value={location.radiusInMeters}
              onChange={(e) =>
                setLocation((prev) => ({
                  ...prev,
                  radiusInMeters: e.target.value,
                }))
              }
            />
          </div>
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            disabled={loading}
          >
            {loading ? "Searching..." : "Search"}
          </button>
        </form>
      </div>

      <div className="h-[600px] rounded-lg overflow-hidden">
        <GoogleMap
          zoom={10}
          center={{
            lat: parseFloat(location.latitude),
            lng: parseFloat(location.longitude),
          }}
          mapContainerClassName="w-full h-full"
        >
          {chargingLocations.map((loc) => (
            <Marker
              key={loc.id}
              position={{ lat: parseFloat(loc.latitude), lng: parseFloat(loc.longitude) }}
              onClick={() => setSelectedMarker(loc)}
            />
          ))}

          {selectedMarker && (
            <InfoWindowMap
              marker={selectedMarker}
              onClose={() => setSelectedMarker(null)}
            />
          )}
        </GoogleMap>
      </div>
    </div>
  );
};

export default UserLocationMap;