import React, { useState } from "react";
import { activateOperator, activatePartner, addPlanToPartner, getEligiblePlans } from "../../api";

const PartnerManagement = () => {
  const [partnerId, setPartnerId] = useState("");
  const [operatorId, setOperatorId] = useState("");
  const [planId, setPlanId] = useState("");
  const [eligiblePlans, setEligiblePlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [responseData, setResponseData] = useState("");

  const fetchEligiblePlans = async () => {
    setLoading(true);
    setMessage("");
    try {
      const plans = await getEligiblePlans(partnerId);
      setEligiblePlans(plans || []);
      setResponseData(plans);
      setMessage(plans.length ? "Plans fetched successfully!" : "No plans found.");
    } catch (error) {
      setMessage("Error fetching plans.");
    } finally {
      setLoading(false);
    }
  };

  const handleAddPlan = async () => {
    setLoading(true);
    try {
      if (!partnerId || !planId) {
        alert("❌ Please provide both Partner ID and Plan ID.");
        setLoading(false);
        return;
      }
      const response = await addPlanToPartner(partnerId, planId);
      setResponseData(response);
      alert("✅ Plan added successfully!");
    } catch (error) {
      alert("❌ Error adding plan.");
    } finally {
      setLoading(false);
    }
  };

  const handleActivatePartner = async () => {
    setLoading(true);
    setMessage("");
    try {
      const response = await activatePartner(partnerId);
      setResponseData(response);
      setMessage(response ? "Partner activated successfully!" : "Failed to activate partner.");
    } catch (error) {
      setMessage("Error activating partner.");
    } finally {
      setLoading(false);
    }
  };

  const handleActivateOperator = async () => {
    setLoading(true);
    setMessage("");
    try {
      const response = await activateOperator(operatorId);
      setResponseData(response);
      setMessage(response ? "Operator activated successfully!" : "Failed to activate operator.");
    } catch (error) {
      setMessage("Error activating operator.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6 max-w-lg mx-auto bg-white rounded-lg shadow-md space-y-6">
      <h2 className="text-2xl font-bold text-gray-700 text-center">Partner & Operator Management</h2>

      {message && <p className="text-sm text-gray-600 p-2 text-center">{message}</p>}

      {/* Get Eligible Plans Section */}
      <div className="space-y-3">
        <input
          type="text"
          placeholder="Enter Partner ID"
          value={partnerId}
          onChange={(e) => setPartnerId(e.target.value)}
          className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
        />
        <button
          onClick={fetchEligiblePlans}
          className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition"
          disabled={loading}
        >
          {loading ? "Fetching Plans..." : "Get Eligible Plans"}
        </button>
      </div>

      {/* Display Eligible Plans */}
      {eligiblePlans.length > 0 && (
        <div className="space-y-2">
          <h3 className="text-lg font-semibold text-gray-700">Select a Plan:</h3>
          <ul className="p-2 border rounded bg-gray-50">
            {eligiblePlans.map((plan) => (
              <li
                key={plan.planId}
                className={`p-2 cursor-pointer hover:bg-gray-200 rounded transition ${
                  planId === plan.planId ? "bg-blue-300 font-bold text-white" : "text-gray-700"
                }`}
                onClick={() => setPlanId(plan.planId)}
              >
                {plan.planName} ({plan.planId})
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Add Plan Section */}
      <div className="space-y-3">
        <input
          type="text"
          placeholder="Enter Plan ID"
          value={planId}
          onChange={(e) => setPlanId(e.target.value)}
          className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-green-400"
        />
        <button
          onClick={handleAddPlan}
          className="w-full bg-green-500 text-white p-2 rounded hover:bg-green-600 transition"
          disabled={loading}
        >
          {loading ? "Adding Plan..." : "Add Plan"}
        </button>
      </div>

      {/* Activate Partner */}
      <button
        onClick={handleActivatePartner}
        className="w-full bg-purple-500 text-white p-2 rounded hover:bg-purple-600 transition"
        disabled={loading}
      >
        {loading ? "Activating Partner..." : "Activate Partner"}
      </button>

      {/* Activate Operator Section */}
      <div className="space-y-3">
        <input
          type="text"
          placeholder="Enter Operator ID"
          value={operatorId}
          onChange={(e) => setOperatorId(e.target.value)}
          className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-red-400"
        />
        <button
          onClick={handleActivateOperator}
          className="w-full bg-red-500 text-white p-2 rounded hover:bg-red-600 transition"
          disabled={loading}
        >
          {loading ? "Activating Operator..." : "Activate Operator"}
        </button>
      </div>

      {/* JSON Response View */}
      {responseData && (
        <div className="mt-6 bg-gray-100 p-4 rounded-lg shadow-md">
          <h2 className="text-lg font-semibold mb-2">API Response:</h2>
          <pre className="text-sm text-gray-800 bg-gray-200 p-2 rounded-md overflow-auto">
            {JSON.stringify(responseData, null, 2)}
          </pre>
        </div>
      )}
    </div>
  );
};

export default PartnerManagement;
