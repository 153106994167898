import React, { useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { AuthService } from "../AuthService";
import { ROUTES } from "../routes";
import { loginUser } from "../api";

export function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [loginMethod, setLoginMethod] = useState("email");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await loginUser({
        email,
        password,
      });

      if (response?.token) {
        AuthService.setToken({
          token: response.token,
          email: response.email,
          roles: response.roles,
        });

        window.location.href = ROUTES.HOME;
      }
    } catch (error) {
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        navigate(ROUTES.HOME);
      } catch (error) {
      }
    },
    onError: () => {},
  });

  return (
    <form onSubmit={handleLogin} className="space-y-4">
      <div className="flex space-x-4 mb-4">
        <button
          type="button"
          className={`flex-1 py-2 px-4 rounded ${
            loginMethod === "email" ? "bg-blue-500 text-white" : "bg-gray-200"
          }`}
          onClick={() => setLoginMethod("email")}
        >
          Email
        </button>
        <button
          type="button"
          className={`flex-1 py-2 px-4 rounded ${
            loginMethod === "phone" ? "bg-blue-500 text-white" : "bg-gray-200"
          }`}
          onClick={() => setLoginMethod("phone")}
        >
          Phone
        </button>
      </div>

      {loginMethod === "email" ? (
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full px-3 py-2 border rounded"
          required
        />
      ) : (
        <input
          type="tel"
          placeholder="Phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          className="w-full px-3 py-2 border rounded"
          required
        />
      )}

      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className="w-full px-3 py-2 border rounded"
        required
      />

      <button
        type="submit"
        className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
      >
        Login
      </button>

      <button
        type="button"
        onClick={() => googleLogin()}
        className="w-full bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
      >
        Login with Google
      </button>
    </form>
  );
}

export default Login;



